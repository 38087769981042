import { Spinner } from "@telegram-apps/telegram-ui"



export const LoaderBlock = () => {
    return (
        <div style={{display:"flex", alignItems:"center", justifyContent:"center", width:"100%" , height: "120px"}}>
            <Spinner />
        </div>
    )
}