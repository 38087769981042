import { Banner, Button, List } from "@telegram-apps/telegram-ui"
import React from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { postKycThunk } from "../../../store/thunks/userThunk"
import { useNotification } from "../../../helpers/notification"
import VibrateModule from "../../../helpers/vibrate"


export const Banners = () => {
    const nav = useNavigate()
    const dispatch = useDispatch()

    const {user: userInfo, kyc: kycInfo, loading, error } = useSelector(state => state.user)
    const { cards } = useSelector(state => state.cards)

    const { showNotification } = useNotification();

    return (
        (userInfo?.user?.sumsubVerified === false) ?
        <List>
            <Banner header="To issue a card, go through verification">
                <React.Fragment key=".0">
                    <VibrateModule>
                    <Button size="s" onClick={() => {
                        //if(Number(userInfo?.user?.balances?.usd) <= 0) return showNotification("Error", "You need to top-up your balance", "error"); 
                        dispatch(postKycThunk())
                    }}>
                        Go through verification
                    </Button>
                    </VibrateModule>
                </React.Fragment>
            </Banner>
        </List>
        : (cards?.length === 0) ? <List>
            <Banner header={`Nice to meet you, ${userInfo.user.name ? userInfo.user.name : "our user"}. Do you want to issue your first card?`}>
                <React.Fragment key=".0">
                    <VibrateModule>
                    <Button size="s" onClick={() => nav("/open/card")}>
                        Let's go!
                    </Button>
                    </VibrateModule>
                </React.Fragment>
            </Banner>
        </List> 
        : <></>
    )
}