import { Button, Placeholder, Steps } from "@telegram-apps/telegram-ui";
import { useState } from "react";
import Loader from "../../helpers/loader";
import { useSelector } from "react-redux";

// Пути к вашим анимациям
import WelcomeTgs from './../../assets/tgs/welcome.tgs';
import WorldwideTgs from './../../assets/tgs/word_wide.tgs';
import ApplePayTgs from './../../assets/tgs/ap_gp.tgs';
import PaymentsTgs from './../../assets/tgs/payments.tgs';
import KycTgs from './../../assets/tgs/kyc.tgs';

const OverviewBlocks = () => {
    const { user: userInfo } = useSelector(state => state.user);
    const [step, setStep] = useState(1);

    // Определение анимации для каждого шага
    const getTgsSrcForStep = (step) => {
        switch (step) {
            case 1:
                return WelcomeTgs;
            case 2:
                return WorldwideTgs;
            case 3:
                return ApplePayTgs;
            case 4:
                return PaymentsTgs;
            case 5:
                return KycTgs;
            default:
                return WelcomeTgs; // В случае, если шаг некорректен, возвращаем стандартную анимацию
        }
    };

    return (
        <div className="overview__blocks">
            <div className="overview__blocks__steps" onClick={() => setStep(prev => {
                        if (prev === 1) return 1;
                        return prev - 1;
                    })}>
                <Steps
                    count={5}
                    progress={step}
                />
            </div>

            {step === 1 ? (
                <Placeholder 
                    action={<Button onClick={() => setStep(2)}>Let’s go!</Button>}
                    description="BFinance is a financial service right in the Telegram. Let's tell you more about it"
                    header={`Welcome${userInfo.user.name ? ", " + userInfo.user.name : ""}!`}
                >
                    <tgs-player key={step} autoplay loop mode="normal" src={getTgsSrcForStep(step)} style={{width: 150, height: 150}}></tgs-player>
                </Placeholder>
            ) : step === 2 ? (
                <Placeholder 
                    action={<Button onClick={() => setStep(3)}>Wow! Next?</Button>}
                    description="Our cards are accepted at any offline and online store, over 50 million merchants worldwide."
                    header="Worldwide accepted cards"
                >
                    <tgs-player key={step} autoplay loop mode="normal" src={getTgsSrcForStep(step)} style={{width: 150, height: 150}}></tgs-player>
                </Placeholder>
            ) : step === 3 ? (
                <Placeholder 
                    action={<Button onClick={() => setStep(4)}>Nice</Button>}
                    description="Add the card to your e-wallet and pay at any local store in any currency."
                    header="Apple Pay & Google Pay"
                >
                    <tgs-player key={step} autoplay loop mode="normal" src={getTgsSrcForStep(step)} style={{width: 150, height: 150}}></tgs-player>
                </Placeholder>
            ) : step === 4 ? (
                <Placeholder 
                    action={<Button onClick={() => setStep(5)}>Incredible!</Button>}
                    description="Top up your cell phone balance, pay utility bills, recharge Steam, buy gift cards and much more via our app"
                    header="Pay with crypto for anything"
                >
                    <tgs-player key={step} autoplay loop mode="normal" src={getTgsSrcForStep(step)} style={{width: 150, height: 150}}></tgs-player>
                </Placeholder>
            ) : step === 5 ? (
                <Placeholder 
                    action={<Button onClick={() => window.location.reload()}>Okay. Take me to the app</Button>}
                    description="In order to get full access to the app, you need to go through identity verification. This doesn't take much time"
                    header="Identity verification"
                >
                    <tgs-player key={step} autoplay loop mode="normal" src={getTgsSrcForStep(step)} style={{width: 150, height: 150}}></tgs-player>
                </Placeholder>
            ) : <Loader />}
        </div>
    );
};

export default OverviewBlocks;
