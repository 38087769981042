import { Avatar, Button, Cell, Input, List, Modal, Placeholder, Section, Spinner, Text } from "@telegram-apps/telegram-ui"
import { ModalClose } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalClose/ModalClose"
import { ModalHeader } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader"
import { Icon28Close } from "@telegram-apps/telegram-ui/dist/icons/28/close"
import { useEffect, useState } from "react"
import WarnIMG from "./../assets/img/warn.png"
import { chooseCountry, getCards, getCategories, getCountries, getPhoneTopupProducts, getServices, postPhoneTopup, postPhoneTopupPayment } from "../api/api"
import toast from "react-hot-toast"
import Loader from "../helpers/loader"
import Back from "../helpers/back"
import CountrieList from "./components/payments/services/components/countriesList"
import ToCardImg from "./../assets/img/transactions/Payment.png"
import VibrateModule from "../helpers/vibrate"
import EsimService from "./components/payments/services/esim/esim"
import PhoneTopup from "./components/payments/services/phone_topup/phonetopup"
import { useLocation, useNavigate } from "react-router-dom"
import TapBar from "./components/tabbar/tabbar"
import MainButton from "../helpers/mainbutton"
import { useNotification } from "../helpers/notification"
import { SectionHeader } from "@telegram-apps/telegram-ui/dist/components/Blocks/Section/components/SectionHeader/SectionHeader"
import ToCardService from "./components/payments/providers/ToCardService"
import GiftCardService from "./components/payments/providers/GiftCardService"
import { usePlatform } from "@telegram-apps/telegram-ui/dist/hooks/usePlatform"

const ChooseCountryPage = ({ setChooseLang, countries, setMe, me }) => {
    

    const [modalStatus, setModalStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    const [searchQuery, setSearchQuery] = useState("")
    const [filteredCountries, setFilteredCountries] = useState(countries) 

    const {showNotification} = useNotification()

    const handleChooseCountry = async (country) => {
        if(loading) return 
        setLoading(true)
        try {
            const res = await chooseCountry(String(country.isoName).toUpperCase())
            if (res.status === "success") {
                setMe({ ...me, user: { ...me.user, paymentCountry: String(country.isoName).toUpperCase() } })
                setChooseLang(false)
            } else if (res.status === "error") {
                console.log(res)
                setModalStatus(true)
                showNotification("Error",res.error.message, "error") //toast.error();
            }
        } finally {
            setLoading(false)
        }
    }

    return (
        <List>
            <Back callback={() => setChooseLang(false)}/>
            <Input
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search country"
                className="input"
            />
            <CountrieList searchQuery={searchQuery} countries={filteredCountries} handleChooseCountry={handleChooseCountry} />
            <Modal
                open={modalStatus}
                header={
                    <ModalHeader
                        after={<ModalClose onClick={() => setModalStatus(false)}><Icon28Close style={{ color: 'var(--tg-theme-text-color)' }} /></ModalClose>}
                    >
                        Notification
                    </ModalHeader>
                }
                dismissible={false}
            >
                <Placeholder
                    description="Payments will be made with a currency exchange fee"
                    header="We don’t support your country directly"
                    action={<Button onClick={() => setModalStatus(false)} stretched size="l">Okay, proceed</Button>}
                >
                    <Avatar src={WarnIMG} size={64} />
                </Placeholder>
            </Modal>
        </List>
    )
}


const OnlinePayments = ({me, setMe}) => {
    const [servises, setServises] = useState(null)
    const platform = usePlatform()
    const nav = useNavigate()
    const [providers, setProviders] = useState(null)

    const [countries, setCountries] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            const res = await getCountries()
            if (res.status === "error") {
                console.log(res)
                return showNotification("Error",res.error.message, "error") //toast.error();
            }
            setCountries(res.data)
        }
        fetchData()
    }, [])


    const location = useLocation()

    const getQueryParams = () => {
        return new URLSearchParams(location.search);
    };

    

    const [chooseLang, setChooseLang] = useState(false)

    const [selectedService, setSelectedService] = useState(null)
    const [selectedCountry, setSelectedCoutry] = useState(null)
    const [selectedProvider, setSelectedProvider] = useState(null)

    const [language, setLanguage] = useState("")

 
    const {showNotification} = useNotification()
    

    const [loading, setLoading] = useState(false)

    const [step, setStep] = useState(1)
    
    const [cards, setCards] = useState(null)


    async function fetchCategories(language) {
        if(loading === true) return 
        setLoading(true)
        try {
            const res = await getCategories(language);
            if (res.status === "error") {
                console.log(res)
                return showNotification("Error",res.error.message, "error") //toast.error();
            }

            const queryString = getQueryParams().get("tgWebAppStartParam")
            if(String(queryString).includes("esim_")) {
                setSelectedService(res.data.find(serv => serv.title === "eSIM"))
                setStep(5)
            } else if (String(queryString).includes("category_")) {
                setSelectedService(res.data.find(serv => serv.title === String(queryString).replace("category_", "").trim()))
                setStep(2)
            }
            setServises(res.data.sort((a, b) => {
                if (a.title === "Transfers") return -1;
                if (b.title === "Transfers") return 1;
                return 0;
            }));
        } finally {
            setLoading(false)
        }
    }

    async function fetchServices(selectedService) {
        if(loading === true) return 
        setLoading(true)
        try {
            const res = await getServices(language, selectedService.id);
            if (res.status === "error") {
                console.log(res)
                return showNotification("Error",res.error.message, "error") //toast.error();
            }
            let data = res.data
            const isIOS = /iPhone|iPad|iPod|Macintosh/.test(navigator.userAgent);
            if (isIOS) {
                data = data.filter(ob => ob.type !== "denomination");
            }
            setProviders(data)
        } finally {
            setLoading(false)
        }
    }

    async function fetchData() {
        const res = await getCards()
        if (res.status === "error") {
            console.error(res)
            return showNotification("Error",res.error.message, "error") //toast.error();
        }
        setCards(res.data)
    }


    useEffect(() => {
        if(!language) return
        fetchCategories(language)
    }, [language])


    useEffect(() => {
        window.Telegram.WebApp.expand()
        fetchData()
    }, [])

    useEffect(() => {
        if(!countries) return 
        setLanguage(me.user.paymentCountry)
        setSelectedCoutry(countries.find(country => String(country.isoName).toUpperCase() === String(me.user.paymentCountry).toUpperCase()))
    }, [me, countries])

    const getSelectedService = () => {
        return (
            <>
                {selectedService && <Cell onClick={() => {
                    setStep(1)
                    setSelectedService(null)
                }} subhead="Category"  before={<Avatar size={40} src={selectedService.image} />}>
                    {selectedService.title}
                </Cell>}
            </>
        )
    }

    const getSelectedProvider = () => {
        return (
            <>
                {selectedService && <Cell onClick={() => {
                    setStep(2)
                    setSelectedProvider(null)
                }} subhead="Payment"  before={<Avatar size={40} src={selectedProvider.title === "To card" ?  ToCardImg : selectedProvider.image} />}>
                    {selectedProvider.title}
                </Cell>}
            </>
        )
    }

    useEffect(() => {
        if(!selectedService) return
        fetchServices(selectedService)
        setStep(2)
    }, [selectedService])

    if(servises === null || countries === null) return <Loader />
    if(chooseLang) return <ChooseCountryPage setMe={setMe} me={me} setChooseLang={setChooseLang} countries={countries}/>
    if(selectedService?.title === "eSIM") return <EsimService countries={countries} setLoading={setLoading} setStep={setStep} step={step} setSelectedService={setSelectedService} cards={cards} getSelectedService={getSelectedService} loading={loading}/>
    if(selectedService?.title === "Phone top-up") return <PhoneTopup setLoading={setLoading} setStep={setStep} step={step} setSelectedService={setSelectedService} cards={cards} getSelectedService={getSelectedService} loading={loading} />
    
    
    if(selectedProvider?.title === "To card") return <ToCardService selectedCountry={selectedCountry} setLoading={setLoading} setStep={setStep} step={step} setSelectedProvider={setSelectedProvider} cards={cards} getSelectedProvider={getSelectedProvider} loading={loading}/> 
    
    console.log(platform)
    return (
        <>
        {step === 1 && <TapBar active={"payments"} me={me}/>}
        <List style={{paddingBottom:"86px"}}>
            {step === 1 ? <>
            {selectedCountry && <VibrateModule><Cell onClick={() => setChooseLang(true)} description="click to change"  before={<Avatar size={40} acronym={String(selectedCountry.name).charAt(0)} src={selectedCountry.flagUrl}/>}>
                {selectedCountry.name}
            </Cell></VibrateModule>}
            
            {servises && servises.length > 0 ? servises.map((serv, index) => {
                    return (
                        <Section header={index === 0 && "Categories"} >
                            <VibrateModule>
                                <Cell style={{margin:"6px 0"}} before={<Avatar size={40} src={serv.image} />} onClick={() => {
                                    if(serv?.title !== "eSIM" && me?.user?.sumsubVerified === false) {
                                        return showNotification("Error", "Go through verification", "error");
                                    }
                                    setSelectedService(serv)
                                }}>
                                    {serv.title}
                                </Cell>
                            </VibrateModule>
                        </Section>
                    )
            }): <Section header={"Categories"}><Placeholder style={{margin:"30px 0"}} description={"Categories list is empty"}>
                
            </Placeholder></Section>}


            </> : step === 2 ? <>
            <Back callback={() => {
                setStep(1)
                setSelectedService(null)
            }}/>
            {getSelectedService()}
            {(!providers || loading ) ? <Section header="Select service">
                    <div style={{display:"flex", alignItems:"center", justifyContent:"center", width:"100%" , height: "120px"}}>
                        <Spinner />
                    </div>
              </Section>  : providers.length > 0 ? providers.map((serv, index) => {
                return (
                    <Section header={index === 0 && "Select service"}>
                        <VibrateModule><Cell style={{margin:"6px 0"}} before={<Avatar size={40} acronym={String(serv.title).charAt(0)} src={serv.title === "In-app" ? "/img/bfinance-logo.png" : serv.title === "To card" ?  ToCardImg : serv.image} />} onClick={() => {
                            if(serv.title === "In-app") {
                                return nav("/transfer")
                            }

                            if(serv.title === "Steam") {
                                return showNotification("Error", "Coming soon", "error");
                            }
                            setSelectedProvider(serv)
                            setStep(3)
                        }}>
                            {serv.title}
                        </Cell></VibrateModule>
                    </Section>
                )
            }): <Section header={"Select service"}><Placeholder style={{margin:"30px 0"}} description={"Service list is empty"}>
            
            </Placeholder></Section>}
            
            </> : step === 3 ? <>
            <Back callback={() => {
                setStep(2)
                setSelectedProvider(null)
            }}/>
            
            <></>
            {<GiftCardService cards={cards} giftCard={selectedProvider} setStep={setStep} getSelectedProvider={getSelectedProvider}/>}
            </> : <Loader />}
        </List>
        </>
    )
}

export default OnlinePayments