import { Avatar, Cell, Section } from "@telegram-apps/telegram-ui"
import VibrateModule from "../../../../helpers/vibrate"
import MasterCardImg from "./../../../../assets/img/mc_g.png"
import VisaImg from "./../../../../assets/img/visa.png"

const PaymentsList = ({cards, selectPayment, setPayment}) => {
    return (
        <>
        <Section header="Select payment method">
                        {/*<VibrateModule>
                        <Cell subhead="Crypto" onClick={() => {
                            return showNotification("Error", "Temporarily unavailable", "error")
                            selectPayment()
                            setPayment({
                                type: "crypto",
                                value: "ton"
                            })
                        }} before={<Avatar size={48} src={'/img/ton.png'}/>}>
                            TON
                        </Cell>
                        </VibrateModule>*/}
                    </Section>
                    <Section>
                    <VibrateModule>
                        <Cell onClick={() => {
                            selectPayment()
                            setPayment({
                                type: "crypto",
                                value: "usdt"
                            })
                        }} subhead="Crypto" before={<Avatar size={48} src={'/img/usdt.png'}/>}>
                            Dollars
                        </Cell>
                        </VibrateModule>
                    </Section>
                    {cards.length > 0 ? cards?.map(card => {
                        return (
                            <Section>
                                <VibrateModule>
                                <Cell disabled subhead={"Card"} before={<Avatar size={48} src={String(card.brand).toLowerCase() === "mastercard" ? MasterCardImg : String(card.brand).toLowerCase() === "visa" ? VisaImg  : ""} acronym={card.brand.charAt(0)} />} 
                                onClick={() => {
                                    return
                                    selectPayment()
                                    setPayment({
                                        type: "card",
                                        value: card.id
                                    })
                                }}>
                                    {card.brand} {String(card.mask).slice(13).replace(" ", "")}
                                </Cell>
                                </VibrateModule>
                            </Section>
                        )
                    }) : "" }
        </>   
    )
}

export default PaymentsList