import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AppRoot } from '@telegram-apps/telegram-ui';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import Notification, { NotificationProvider } from './helpers/notification';
import { Provider } from 'react-redux';
import setupStore from './store/store';
import * as Sentry from "@sentry/react";

const mode = "prod" 

if(mode === "prod") {
    Sentry.init({
        dsn: "https://723c092eb68bb94c250e6e5e35a0b3d6@o4508111274508288.ingest.de.sentry.io/4508111276343376",
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration(),
        ],
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });
}




const root = ReactDOM.createRoot(document.getElementById('root'));

const store = setupStore()

let htmlElement = window.document.getElementsByTagName("html")[0];

let themeColor = "dark";

if (window.Telegram.WebApp) {
    window.Telegram.WebApp.onEvent('themeChanged', applyTheme); 

    function applyTheme() {
        const theme = window.Telegram.WebApp.themeParams;
        console.log(theme);

        if (theme && theme.bg_color) {
            const bgColor = theme.bg_color.toLowerCase();
            if (bgColor === '#000000' || bgColor.startsWith('#1') || bgColor.startsWith('#2')) {
                themeColor = 'dark';
            } else {
                themeColor = 'light';
            }
        }

        htmlElement.classList.add(themeColor);
    }

    applyTheme();
}


root.render(
    <Provider store={store}>
        <BrowserRouter>
            <Toaster  />
            <AppRoot platform="ios" appearance='dark' className={`${themeColor}`}>
                <NotificationProvider>
                    <App />
                </NotificationProvider>
            </AppRoot>
        </BrowserRouter>
    </Provider>
);
