import SumsubWebSdk from "@sumsub/websdk-react";
import Back from "../helpers/back";
import { useEffect, useState } from "react";
import ErrorPage from "../components/ErrorPage";
import Loader from "../helpers/loader";
import { useNavigate } from "react-router-dom";
import { Placeholder } from "@telegram-apps/telegram-ui";

import SeedImg from "./../assets/img/seed.png"
import MainButton from "../helpers/mainbutton";
import { useDispatch, useSelector } from "react-redux";
import { setKyc } from "../store/reducers/userReducer";

const Kyc = () => {

    const {user: userInfo, kyc: kycInfo, loading } = useSelector(state => state.user)

    const [show, setShow] = useState(false)
    const dispatch = useDispatch()

    const nav = useNavigate()

    if(userInfo?.user?.isVerifyRejected) return <ErrorPage type="verif"/>
    if(!kycInfo.accessToken) return <Loader />

    console.log(kycInfo)
    return (
          <>
            <Back callback={() => {
              dispatch(setKyc(null))
            }}/>

            {!show ? <> <div style={{width:"100%", height:"100vh", display:'flex', alignItems:"center", justifyContent:"center"}}><MainButton text={"Proceed"} myButton withoutTabbar func={() => setShow(true) }/>
            

            <Placeholder header="Verification required" description="To access this feature, you must complete identity verification. Please click button below to verify your identity. This process ensures the security and privacy of your account. If you encounter any issues, please contact our support team for assistance.">
                <img src={SeedImg} style={{width:"112px", height:"112px"}} />
            </Placeholder> </div> </> : 

            <SumsubWebSdk
                    accessToken={kycInfo.accessToken}
                    style={{width:"100vw"}}
                    updateAccessToken={() => console.log("updateAccessToken")}
                    expirationHandler={() => Promise.resolve(kycInfo.accessToken)}
                    config={{
                        lang: "ru-RU",
                        onMessage: (type, payload) => {
                            console.log("WebSDK onMessage", type, payload);
                        },
                        onError: (error) => {
                            console.error("WebSDK onError", error);
                        }
                    }}
                    options={{ addViewportTag: false, adaptIframeHeight: true }}
                    onMessage={(type, payload) => {
                        console.log("onMessage", type, payload);
                    }}
                    onError={(data) => console.log("onError", data)}
                />}

        </>
    )
}

export default Kyc