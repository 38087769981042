import { Avatar, Cell, InlineButtons, List, Placeholder, Text } from "@telegram-apps/telegram-ui";
import { InlineButtonsItem } from "@telegram-apps/telegram-ui/dist/components/Blocks/InlineButtons/components/InlineButtonsItem/InlineButtonsItem";
import { format } from "date-fns";
import { useEffect } from "react";
import {toast} from "react-hot-toast"
import WarnIMG from "../../../../assets/img/warn.png"
import splitNumbers from "../../../../helpers/splitNumbers";
import { useNotification } from "../../../../helpers/notification";
import Loader from "../../../../helpers/loader";

const TransactionPageCard = ({ transaction, onBack, cur }) => {

    const {showNotification} = useNotification()
    
    useEffect(() => {
        window.Telegram.WebApp.BackButton.onClick(onBack);
        window.Telegram.WebApp.BackButton.show();

        return () => {
            window.Telegram.WebApp.BackButton.hide();
            window.Telegram.WebApp.BackButton.offClick(onBack);
        };
    }, [onBack]);

    const copyToClipboard = (text) => {
        try {
        navigator.clipboard.writeText(text).then(() => {
            showNotification("Copied!", "", "success")
        })

    } catch (e) {
        console.log(e)
        return showNotification("Error", "Error while copying", "error") 
      }
    };

    if(!transaction) return <Loader />
    return (
        <div className="transaction-overlay">
            <List>
                <Placeholder className="tr_time" style={{margin:"0", paddingBottom:"10px"}} header={<Text style={{marginBottom:"15px"}}>{transaction.merchant || ""}</Text>}
                    description={<Text >{format(new Date(transaction.transactionDate), 'PPpp')}</Text>}>
                    <Avatar size={64} acronym={transaction.mccDescription.charAt(0)} src={`https://innature.store/public/mcc/${transaction.mcc}` || ""} />
                </Placeholder>
                <Placeholder style={{margin:"5px 0", padding:"15px 0"}} header={<Text style={{fontWeight:"700",textDecoration: transaction.status === "FAILED" && "line-through", fontSize: String(Number(Math.abs(transaction.amount)).toFixed(2)).length > 6 ? "34px" : String(Number(Math.abs(transaction.amount)).toFixed(2)).length > 9 ? "25px" : "40px" }}>
                    {transaction.status === "REVERSED" ? "" : "-"}{splitNumbers(Number(Math.abs(transaction.amount)).toFixed(2))} <span>{String(transaction.currency).toUpperCase()}</span>
                </Text>}
                    description={<Text style={{color: transaction.status === "FAILED" ? "#D13131" : transaction.status === "APPROVED" ||  transaction.status === "CLEARED" ? "#31D158" : ""}}>{String(transaction.status).slice(0, 1).toUpperCase() + String(transaction.status).slice(1).toLocaleLowerCase()}</Text> }>
                </Placeholder>

                {transaction.status === "FAILED" && <Cell className="warn"  description={transaction?.declineReason} before={<Avatar src={WarnIMG} />}>
                 Transaction was declined
                </Cell> }

                <InlineButtons className="transaction__buttons" style={{marginTop:"12px"}}>
                    {transaction.mccDescription && <InlineButtonsItem style={{ maxWidth:"100%", minWidth:"100%" }} onClick={() => copyToClipboard(transaction.mccDescription)} className="transaction__button" mode="gray" text={<Text className="transaction__button__value">{transaction.mccDescription}</Text>}>
                        <Text className="transaction__button__title">Category</Text>
                    </InlineButtonsItem>}
                    {transaction.merchantCity && <InlineButtonsItem className="transaction__button" mode="gray" onClick={() => copyToClipboard(String(transaction.merchantCity).replace(">", ""))} text={<Text className="transaction__button__value">{String(transaction.merchantCity).replace(">", "")}</Text>}>
                        <Text className="transaction__button__title">City</Text>
                    </InlineButtonsItem>}
                    {transaction.merchantCountry && <InlineButtonsItem className="transaction__button" mode="gray" onClick={() => copyToClipboard(String(transaction.merchantCountry))} text={<Text className="transaction__button__value">{String(transaction.merchantCountry)}</Text>}>
                        <Text className="transaction__button__title">Country</Text>
                    </InlineButtonsItem>}
                    {transaction.usdAmount && <InlineButtonsItem className="transaction__button" mode="gray" onClick={() => copyToClipboard(Number(String(transaction.usdAmount).replace("-", "")).toFixed(2))} text={<Text className="transaction__button__value">{cur}{Number(String(transaction.usdAmount).replace("-", "")).toFixed(2)}</Text>}>
                        <Text className="transaction__button__title">Amount</Text>
                    </InlineButtonsItem>}
                    {!transaction.charge && transaction.charge != 0  ? "" :  <InlineButtonsItem className="transaction__button" onClick={() => copyToClipboard(transaction.charge)} mode="gray" text={<Text className="transaction__button__value">{cur}{transaction.charge}</Text>}>
                        <Text className="transaction__button__title">Charge</Text>
                    </InlineButtonsItem>}
                </InlineButtons>

                <Placeholder style={{margin:"10px 0 0 0 ", padding:'0'}} description={<Text onClick={() => copyToClipboard(transaction.transactionId)} ><Text className="tr_number">Transaction number</Text> <br /> <Text className="tr_number_v">{transaction.transactionId}</Text></Text>}>

                </Placeholder>
            </List>
        </div>
    );
}

export default TransactionPageCard;
