import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Main from "./pages/main";
import Cards from "./pages/cards";
import Card from "./pages/card/Card";
import Balance from "./pages/balance";
import Transaction from "./pages/transaction";
import { getRates, getUserInfo, postKyc } from "./api/api";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Loader from "./helpers/loader";
import OpenCard from "./pages/openCard";
import Topup from "./pages/topup";
import Withdraw from "./pages/withdraw";
import Transfer from "./pages/transfer";
import OnlinePayments from "./pages/onlinePayment";
import Account from "./pages/account/Account";
import Kyc from "./pages/kyc";
import PinInputComponent from "./pages/PinPage";
import ErrorPage from "./components/ErrorPage";
import { useNotification } from "./helpers/notification";
import PermissionLayout from "./layouts/PermissionLayout";
import Earn from "./pages/components/main/components/earn";
import PaymnetQR from "./pages/paymentQr/PaymentQR";
import HistoryPage from "./pages/HistoryPage/HistoryPage";
import { useDispatch, useSelector } from "react-redux";

import { getUserThunk, postKycThunk } from "./store/thunks/userThunk";
import { getRatesThunk } from "./store/thunks/settingsThunk";
import { setUser } from "./store/reducers/userReducer";
import MainPage from "./pages/MainPage/MainPage";
import OverviewBlocks from "./modules/OverviewBlocks/OverviewBlocks";

function App() {

  //const [rates, setRates] = useState(null);
  //const [iframeUrl, setAccessToken] = useState(null);
  //const [kyc, setKyc] = useState(false)
  const { showNotification } = useNotification();
  const dispatch = useDispatch()
  const location = useLocation();

  const setMe = async (user) => {
    return dispatch(setUser(user))
  }

  const {user: userInfo, kyc: kycInfo, loading, error } = useSelector(state => state.user)
  const { cards, loading: loadingCard } = useSelector(state => state.cards)
  const { rates } = useSelector(state => state.settings)

  const getQueryParams = () => {
    return new URLSearchParams(location.search);
  };

  /*async function fetchData(ref) {
    const res = await getUserInfo(ref);
    if (res.success === false) {
      return showNotification("Error", res.error.message, "error");
    }
    setMe(res.data);
  }*/

  async function fetchDataKyc() {
    dispatch(postKycThunk())
    /*setKyc(true)
    const res = await postKyc();
    if (res.status === "error") {
      setKyc(false)
      return showNotification("Error", res.error.message, "error");
    }
    //window.Telegram.WebApp.openLink(res.data.iframeUrl)
    setAccessToken(res.data.iframeUrl);*/
  }

  /*async function fetchDataRates() {
    const res = await getRates();
    if (res.status === "error") {
      return showNotification("Error", res.error.message, "error");
    }
    setRates(res.data);
  }
*/
    useEffect(() => {
      if(!error) return 
      return showNotification("Error", error, "error");
    }, [error])
    

    useEffect(() => {
      try {
        const htmlElement = document.documentElement;
      window?.Telegram?.WebApp?.expand();
      window?.Telegram?.WebApp?.disableVerticalSwipes();
     

      if (htmlElement.classList.contains('dark')) {
        window?.Telegram?.WebApp?.setBackgroundColor("#000000");
        window?.Telegram?.WebApp?.setHeaderColor("#000000");
      }
      
      console.log(window?.Telegram?.WebApp?.initData)

      const fetchData = async () => {
        try {
          const queryParams = getQueryParams();
          const ref = queryParams.get('tgWebAppStartParam');
    
          // Выполняем первый thunk
          const refValue = String(ref).includes("esim_") ? "" : String(ref).includes("ref_") ? String(ref).replace("ref_", "") : "";
   
           await dispatch(getUserThunk(refValue));
           dispatch(getRatesThunk());
          //fetchDataRates()
        } catch (error) {
          console.error("Error during fetchData:", error);  // Обрабатываем ошибки
        }
      };
    
      fetchData();
      } catch (e) {
        console.log(e)
      }
    }, []);

    

    

  
  if (loading || rates === null  /*|| !window.Telegram.WebApp.initData*/) return <Loader />;
  if (!userInfo?.user && userInfo?.blocked === true) return <ErrorPage type="pin" />;
  if (!userInfo?.user && userInfo.pinRequired === true) return <PinInputComponent fetchMe={true} setMe={setMe} me={userInfo}  />;

  if(userInfo.user.newUser) return <OverviewBlocks />
  return (
    <div className="app">
      {kycInfo !== null ? (
        <Kyc />
      ) : (
        <Routes>
          <Route path="/" element={
            <MainPage />
          } />
          <Route path="/earn" element={
            <Earn me={userInfo} page={true} fetchDataKyc={fetchDataKyc} rates={rates} setMe={setMe} />
          } />
          <Route path="/account" element={
            <Account me={userInfo} setMe={setMe} />
          } />
          {/*<Route path="/cards" element={
            <PermissionLayout me={userInfo} withoutVerif={true}><Cards me={userInfo} /></PermissionLayout>
          } />*/}
          <Route path="/history" element={
            <PermissionLayout me={userInfo} withoutVerif={true}><HistoryPage me={userInfo} /></PermissionLayout>
          } />
          <Route path="/topup" element={
            <Topup me={userInfo} rates={rates} />
          } />
          <Route path="/withdraw" element={
            <PermissionLayout me={userInfo}><Withdraw me={userInfo} rates={rates} /></PermissionLayout>
          } />
          <Route path="/transfer" element={
            <PermissionLayout me={userInfo}><Transfer me={userInfo} rates={rates} /></PermissionLayout>
          } />
          <Route path="/open/card" element={
            <PermissionLayout me={userInfo} withoutVerif={true}><OpenCard me={userInfo} rates={rates}/></PermissionLayout>
          } />
          <Route path="/card/:id" element={
            <PermissionLayout me={userInfo}><Card me={userInfo} rates={rates} /></PermissionLayout>
          } />
          <Route path="/balance/:type" element={
            <Balance me={userInfo} rates={rates} />
          } />
          <Route path="/online/payments" element={
            <PermissionLayout me={userInfo} withoutVerif={true}><OnlinePayments me={userInfo} setMe={setMe} /></PermissionLayout>
          } />
          <Route path="/payment/:id" element={
            <PermissionLayout me={userInfo}><PaymnetQR me={userInfo} setMe={setMe} /></PermissionLayout>
          } />
          <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
