import { Avatar, Button, Cell, Image, Input, List, Modal, Placeholder, Section, SegmentedControl, Spinner, Text } from "@telegram-apps/telegram-ui"
import Back from "../../../../../../helpers/back"
import VibrateModule from "../../../../../../helpers/vibrate"
import { SectionHeader } from "@telegram-apps/telegram-ui/dist/components/Blocks/Section/components/SectionHeader/SectionHeader"
import { Icon24ChevronRightOutline, Icon24Copy } from "@vkontakte/icons"
import { useEffect, useState } from "react"
import Loader from "../../../../../../helpers/loader"
import { getESIMs, postPhoneTopup } from "../../../../../../api/api"
import toast from "react-hot-toast"
import QRCode from "qrcode.react"
import { useLocation } from "react-router-dom"
import { Map } from "./map"
import { useNotification } from "../../../../../../helpers/notification"

const MyESIMs = ({setStep, step}) => {
    const [data, setData] = useState(null)
    const [selectedESIM, setSelectedESIM] = useState(null)

    const {showNotification} = useNotification()
    
    const location = useLocation()

    const getQueryParams = () => {
        return new URLSearchParams(location.search);
    };

    useEffect(() => {
        if (!data) return;
        
        const esimParam = getQueryParams().get("tgWebAppStartParam");
        if (esimParam && esimParam.includes("esim_")) {
            const cleanedEsimParam = esimParam.replace("esim_", "");
    
            const selectedEsim = data.find((esim) => esim.id === cleanedEsimParam);
            setSelectedESIM(selectedEsim);
        }
    }, [data]);
    


    useEffect(() => {
        async function getInfo(params) {
            const res = await getESIMs();
            if (res.status === "error") {
                console.error(res)
                return showNotification("Error",res.error.message, "error") //toast.error();
            }
            setData(res.data)
        }
        getInfo()
    }, [])

    const generateRandomColor = () => {
        const randomColor = 'white';
        return randomColor;
      };

    const colorCountry = (isoCode, color) => {
        if(isoCode.length > 5) return
        const countryPath = document.querySelector(`svg #${isoCode}`);
        if (countryPath) {
          countryPath.style.fill = color;
        }
      };

    useEffect(() => {
        if(!selectedESIM) return 
        const countryCodes = new Set();

        if(selectedESIM.countries.length > 1) {
            selectedESIM.countries.forEach(country => {
                countryCodes.add(country.isoName);
            });
        } else {
            countryCodes.add(selectedESIM.country.isoName);
        }


        

        countryCodes.forEach(code => {
            const color = generateRandomColor();
            colorCountry(code, color);
        });

    }, [selectedESIM])

    const [avalibleCountries, setAvalibleCountries] = useState(null)


    console.log(selectedESIM)
    
    if(data === null) return <Loader />
    return (
        selectedESIM ?

        avalibleCountries ? <>
        <Back callback={() => {
            setAvalibleCountries(false)
        }}/>
        
        <Section header="AVAILABLE COUNTRIES">
                    {selectedESIM.countries ? selectedESIM.countries.map(country => {
                        return (
                        <Cell 
                        description={
                            [
                                country.network_coverage.some(network => network.two_g) ? "2G" : null,
                                country.network_coverage.some(network => network.three_g) ? "3G" : null,
                                country.network_coverage.some(network => network.four_G) ? "4G" : null,
                                country.network_coverage.some(network => network.five_G) ? "5G" : null
                            ]
                            .filter(Boolean) // Remove null values
                            .join(", ") // Join the values with a comma and space
                        }
                         
                        subhead={
                            country.network_coverage.length === 0 ? "" : `${country.network_coverage[0].network_name} ${country.network_coverage.length > 1 ? ", +"+String(country.network_coverage.length - 1) : ""}` 
                        } onClick={() => {
                        }} before={<Avatar size={28} src={country.image_url}/>}>
                            {country.name}
                        </Cell>
                        )
                    }) : <Placeholder style={{ margin: "14px 0" }} description={"Avalible countries not found"} />}
                    
        </Section></> : 
<>
                 <Back callback={() => {
                     setSelectedESIM(null)
                 }}/>
                 <>
                 <Placeholder header={String(selectedESIM.type).slice(0,1).toUpperCase()+String(selectedESIM.type).slice(1)+" eSIM"} description={selectedESIM.sim.status}>
                 <Map />

                 </Placeholder>
                 {selectedESIM.sim.status === "Installed" ? <>
                    <Section>
                         <SectionHeader style={{paddingTop:"16px"}}>Usage</SectionHeader>
                         {data === null ? <>
                             <div style={{display:"flex", alignItems:"center", justifyContent:"center", width:"100%" , height: "120px"}}>
                                 <Spinner />
                             </div>
                             

                         </> : data ? <>
                            <VibrateModule>
                                <Cell 
                                    style={{ margin: "2px 0" }}
                                    description={selectedESIM.usage.initial_data_quantity+selectedESIM.usage.initial_data_unit}
                                    after={<div style={{display:"flex", alignItems:"end", gap:"4px", whiteSpace:"nowrap", flexDirection:"column"}}>
                                        <Text className="vkuiIcon">{selectedESIM.usage.rem_data_quantity} {selectedESIM.usage.rem_data_unit}</Text>
                                        <Text>available</Text>
                                    </div>}
                                >
                                    Data
                                </Cell>
                            </VibrateModule>
                         </> : <Placeholder style={{ margin: "14px 0" }} description={"Package list is empty"} />}
                     </Section>

                     {selectedESIM.countries.length > 0 && <Cell onClick={() => setAvalibleCountries(true)} after={<Icon24ChevronRightOutline className="right__arrow" width={16} height={16} />}>
                            Available countries
                    </Cell>}
                 
                 </> : <>
                 

                 <Section header="scan qr-code">
                        <Placeholder style={{margin:"16px 0"}}>
                            <div className="qr__bg">
                                <QRCode value={selectedESIM.sim.qr_code_text} size={130} />
                            </div>
                        </Placeholder>
                    </Section>

                    <Section header="or enter details manually">
                        <Cell onClick={() => {
                            try {
                                navigator.clipboard.writeText(selectedESIM.sim.matching_id)
                                return showNotification("Copied!", "", "success")
                            } catch (e) {
                                console.log(e)
                                return showNotification("Error", "Error while copying", "error") 
                            }
                        }}  description="Activation code" after={<Icon24Copy width={22} height={22}/>}>
                            {selectedESIM.sim.matching_id}
                        </Cell>
                        <Cell onClick={() => {
                            try {
                            navigator.clipboard.writeText(selectedESIM.sim.smdp_address)
                            return showNotification("Copied!", "", "success")
                        } catch (e) {
                            console.log(e)
                            return showNotification("Error", "Error while copying", "error") 
                          }
                        }} description="SMDP Address" after={<Icon24Copy  width={22} height={22}/>} >
                            {selectedESIM.sim.smdp_address}
                        </Cell>
                    </Section>

                    <Section>
                        <Cell onClick={() => {
                            window.open("https://guide.bfinance.app")
                        }} before={<Image size={32} src="/img/faq.png"/>}>
                            Guide
                        </Cell>
                    </Section>
                 </>}
                 

                    
                 </>
             </> : <>
                <Back callback={() => {
                     setStep(2)
                 }}/>
                 <Section header="Your esims"></Section>
                    {data && data.length > 0 ? <>
                        {data.map(esim => {
                            return (
                                <VibrateModule>
                                    <Section>
                                        <Cell onClick={() => setSelectedESIM(esim)} subhead={String(esim.type).slice(0,1).toUpperCase()+String(esim.type).slice(1)+" eSIM"} description={esim.sim.status}>
                                            {esim.sim.last_bundle}
                                        </Cell>
                                    </Section>
                                </VibrateModule>
                            )
                        })}
                    </> : <Section><Placeholder style={{ margin: "14px 0" }} description={"eSIM list is empty"} /></Section>}
                 
             
             </>
    )
}

export default MyESIMs