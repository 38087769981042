import { Avatar, Cell, List, Modal, Placeholder, Section, Text } from "@telegram-apps/telegram-ui"
import VibrateModule from "../../../helpers/vibrate"
import { useNavigate } from "react-router-dom"
import VisaImgG from "../../../assets/img/visa.png"
import MasterCardImgG from "../../../assets/img/mc_g.png"
import CardListImgV from "../../../assets/img/card/card_list_visa.png"
import CardListImgM from "../../../assets/img/card/card_list_mc.png"

import { useDispatch, useSelector } from "react-redux"
import { LoaderBlock } from "../../../components/LoaderBlock"
import Loader from "../../../helpers/loader"
import { useEffect } from "react"
import { getCardsThunk } from "../../../store/thunks/cardsThunks"
import { useNotification } from "../../../helpers/notification"


export const CardsList = () => {
    const nav = useNavigate()
    const dispatch = useDispatch()
    const {showNotification} = useNotification()

    const { cards, loading } = useSelector(state => state.cards)

    useEffect(() => {
        if(!cards) return
        let timer = setInterval(() => {
            if(cards.find(card => String(card.mask).slice(13).replace(" ", "") === "*0000")) {
                dispatch(getCardsThunk())
            } else {
                clearInterval(timer)
            }
        }, 2000)
        return () => clearInterval(timer)
    }, [cards])

    return (
        <>
            <List>
                <Section header="CARDS" style={{marginBottom:"0px"}}></Section>
                {loading ? <LoaderBlock /> : <div className="cards__info__block">
                    {cards ? cards.map((card) => {
                        return (
                            <VibrateModule>
                                <Cell 
                                onClick={() => {
                                    if(String(card.mask).slice(13).replace(" ", "") === "*0000") return showNotification("Error", "Wait for the card to be activated", "error")
                                    nav("/card/"+card.id)
                                }} 
                                disabled={String(card.mask).slice(13).replace(" ", "") === "*0000"}
                                subtitle={`${card.cardBalance ? Number(card.cardBalance).toFixed(2) : 0} $`} 
                                after={<Text>{String(card.mask).slice(13).replace(" ", "")}</Text>} 
                                //before={<Avatar size={40} src={String(card.brand).toLowerCase() == "visa" ? VisaImgG : MasterCardImgG }/>}
                                before={<img src={String(card.brand).toLowerCase() == "visa" ? CardListImgV: CardListImgM} style={{
                                    width:"60px",
                                    borderRadius:"5px"
                                }} />}
                                >
                                    {card.brand}
                                </Cell>
                            </VibrateModule>
                        )
                    }) : ""}
                </div>}
            </List>
        </>
    )
}