import { Cell, Image, List, Section } from "@telegram-apps/telegram-ui";
import MainButton from "../../../helpers/mainbutton";
import VibrateModule from "../../../helpers/vibrate";
import { SectionHeader } from "@telegram-apps/telegram-ui/dist/components/Blocks/Section/components/SectionHeader/SectionHeader";
import Back from "../../../helpers/back";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getPersonalData, removePinCode } from "../../../api/api";
import Loader from "../../../helpers/loader";
import PinInputComponent from "../../PinPage";
import { notification, useNotification } from "../../../helpers/notification";

export const SecurityData = ({ me, setSecurityDataStatus, setMe }) => {
    const [pinChange, setPinChange] = useState(false);
    const [pinRemove, setPinRemove] = useState(false);

    const {showNotification} = useNotification()

    const handleRemove = async (pin) => {
        const res = await removePinCode({pin: pin.join("")})
        if (res.status === "error") {
            console.error(res)
            return toast.error(`${res.error.message}`);
        }
        setMe({...me, 
            user: {
            ...me.user,
            havePIN: false
        }})
        setPinRemove(false)
        return showNotification("Successfully", "You removed the PIN", "success") //toast.success("Successfully")
    }

    const handleCreate = async (pin) => {
        setPinChange(false)
        setMe({...me, 
            user: {
            ...me.user,
            havePIN: true
        }})
        return showNotification("Successfully", "You added the PIN", "success") //toast.success("Successfully")
    }

    if(pinChange) return <PinInputComponent functionSuccess={handleCreate} me={me}  createPin setMe={setMe} back={() => setPinChange(false)}/>
    if(pinRemove) return <PinInputComponent functionSuccess={handleRemove} me={me}  setMe={setMe} back={() => setPinRemove(false)}/>
    return (
        <>
            <Back callback={() => setSecurityDataStatus(false)} />
            <List>
                    <Section header="PIN" style={{marginBottom:"0px"}}></Section>
                    <div className="cards__info__block">
                        <VibrateModule>
                            <Cell onClick={() => {
                                if(me.user.havePIN) {
                                    setPinRemove(true)
                                } else {
                                    setPinChange(true)
                                }
                                
                            }} before={<Image style={{borderRadius:"8px"}}  size={32} src={me.user.havePIN ? "/account/remove.png" : "/account/pass.png"}/>}>
                                {me.user.havePIN ? "Remove PIN code" : "Add PIN code" }
                            </Cell>
                        </VibrateModule>
                    </div>
            </List>
        </>
    );
};
