// settingsThunk.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRates } from "../../api/api";

export const getRatesThunk = createAsyncThunk(
  "settings/getRates",
  async (_, thunkAPI) => {
    try {
      const response = await getRates();
      
      // Проверяем, есть ли ошибка в ответе
      if (response.status === "error") {
        console.error(response)
        // Отправляем ошибку через rejectWithValue, чтобы обработать её в extraReducers
        return thunkAPI.rejectWithValue(response.error?.message || "Unknown error");
      }

      return response.data;
    } catch (error) {
      // Если произошла ошибка в запросе, её также передаем через rejectWithValue
      return thunkAPI.rejectWithValue(error.message || "Failed to fetch rates");
    }
  }
);
