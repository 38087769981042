import Back from "../../../helpers/back"


const TermsOfUse = ({setTermsStatus}) => {
    return (
        <>
            <Back callback={() => setTermsStatus(false)} />
            <iframe className="iframe" src="https://cdn.bfinance.app/terms-of-use.pdf#toolbar=0" width={"100%"} height={"100%"}/>
        </>
    )
}

export default TermsOfUse