import { createSlice } from "@reduxjs/toolkit";
import { getCardsThunk } from "../thunks/cardsThunks";

const initialState = {
  cards: null,
  loading: true,
  error: "",
};

const cardsReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCards(state, action) {
      state.cards = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCardsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.cards = action.payload;
      })
      .addCase(getCardsThunk.pending, (state) => {
        
      })
      .addCase(getCardsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; 
      })   
  },
});

export const { setCards} = cardsReducer.actions;

export default cardsReducer.reducer;
