import { Avatar, Badge, Button, Cell, Input, Modal, Placeholder, Section, Spinner, Text } from "@telegram-apps/telegram-ui";
import MainButton from "../../../../helpers/mainbutton";
import { useEffect, useState } from "react";
import { useNotification } from "../../../../helpers/notification";
import { ModalHeader } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import { Icon28Close } from "@telegram-apps/telegram-ui/dist/icons/28/close";
import { ModalClose } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalClose/ModalClose";
import { getDenominationsForGiftCard, postDenominationsForGiftCard } from "../../../../api/api";
import VibrateModule from "../../../../helpers/vibrate";
import PaymentsList from "../components/PaymentsList";
import Back from "../../../../helpers/back";
import Loader from "../../../../helpers/loader";
import TransferPage from "../../transactions/transactionPages/transfer";

const GiftCardService = ({ giftCard, setStep, cards, getSelectedProvider }) => {
  const [payment, setPayment] = useState(null)
  const { showNotification } = useNotification();
  const [result, setResult] = useState(false)
  const [modalActive , setModalActive] = useState(false)


  const [promo, setPromo] = useState(null)
  const [promoStatus, setPromoStatus] = useState(false)

  const [fieldsRange , setfieldsRange] = useState(null)

  const selectPayment = async () => {
    setModalActive(true)
}

  const [email, setEmail] = useState("");

  const [selectedPrice, setSelectedPrice] = useState(null)

  const [prices, setPrices] = useState(null)

  const [loading, setLoading] = useState(false);

  const [stepGift, setStepGift] = useState(1);



  async function handleGetDenomination() {
    if(loading === true) return 
    setLoading(true)
    try {
        const res = await getDenominationsForGiftCard(giftCard.id)
        if (res.status === "error") {
          console.error(res)
            return showNotification("Error",res.error.message, "error")
        }
        setPrices(res.data.denominations)
    } finally {
        setLoading(false)
    }
    
}

  useEffect(() => {
    if(!giftCard) return
    if (giftCard.type === "denomination") {
      setStepGift(1);
      handleGetDenomination()
    } else {
      setStepGift(2);
      setfieldsRange(giftCard.fields.map(field => {
        return {
          ...field,
          value: ""
        }
      }))
    }
  }, [giftCard]);

  const handleBuyGiftCard = async () => {
    if (!payment || !selectedPrice) {
      return showNotification("Enter a valid amount", "Enter amount greater than 0", "error");
    }

    setLoading(true);
    //return console.log(giftCard.id, selectedPrice.id, {payData: payment.value, payType: payment.type, email: email})
    try {
      const res = await postDenominationsForGiftCard(giftCard.id, selectedPrice.id, {payData: payment.value, payType: payment.type, email: promo == 1 ? email : ""})
      if (res.status === "error") {
        console.error(res)
        return showNotification("Error",res.error.message, "error")
      }
      setResult(res.data.message)
      //return showNotification("Success","Gift card bought successfully", "success") 
    } finally {
        setLoading(false)
    }
  };


  console.log(fieldsRange)


  const handleChangeRenageValue = (e) => {
    setfieldsRange(prev => prev.map((field) => {
      if (field.name === e.target.name) {
        let value = e.target.value;
        return {
          ...field,
          value: value
        };
      }
  
      return field;
    }));
  }

  async function postRangeValues() {
    return 
    let isValid = true;
    fieldsRange.forEach((field) => {
      const value = field.value;

      if (field.type === "decimal") {
        const validation = JSON.parse(field.validationRule);
        const numValue = parseFloat(value);
        
        if (numValue < validation.min || numValue > validation.max) {
          showNotification(
            "Error",
            `Value for ${field.name} should be between ${validation.min} and ${validation.max}`,
            "error"
          );
          isValid = false;
          return;
        }
      }

      if (field.type === "string") {
        const validation = JSON.parse(field.validationRule);
        const regex = new RegExp(validation.regex);
        if (!regex.test(value)) {
          showNotification(
            "Error",
            `Invalid format for ${field.name}`,
            "error"
          );
          isValid = false;
          return;
        }
      }
    });

    if (isValid) {
      setLoading(true)
      try {
        
        const res = await ({phone: email.trim()})
        if (res.status === "error") {
          return showNotification("Error",res.error.message, "error")
        }
        //setStep(2)
        //return showNotification("Success","Gift card bought successfully", "success")
        setResult(res.data.message)
      } finally {
        setLoading(false)
      }
    }
  }

  
  
  

  if(result) return <TransferPage handleClose={() => {
      setResult(null)
      setStep(2)
  }} transfer={{amount: giftCard.type === "denomination" ? Number(selectedPrice?.billAmount * giftCard?.fee).toFixed(2) : Number(Number(fieldsRange?.find(field => String(field.name).toLowerCase() == "amount").value) * giftCard?.fee).toFixed(2), cur: "USD" }} head={"Success"} info={result ? result : "Gift card bought successfully!"}/>
  return (
    <>
      {getSelectedProvider()}
      {stepGift === 1 ? (
        <>
          {!promoStatus ? <><Section header="Who is the promo code for?">
            <VibrateModule>
                <Cell titleBadge={promo === 2 ?< Badge type="dot" /> : false} onClick={async () => {
                    setPromo(2);
                }}>
                    For me
              </Cell>
            </VibrateModule>
            <VibrateModule>
                <Cell titleBadge={promo === 1 ? < Badge type="dot" /> : false} onClick={async () => {
                    setPromo(1);
                }}>
                    For other person
              </Cell>
            </VibrateModule>
            
          </Section>
          
            {promo !== null && <MainButton text={"Next"} func={() => {
              if(promo == 1) return setPromoStatus(true)
              setStepGift(2)
            }}  myButton
            withoutTabbar/>}
            
            </> : <><Section header="Enter your email">
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter email"
              className="input"
            />
            
          </Section>
          
          <MainButton
              text={"Next"}
              func={() => {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!email.trim() || !emailRegex.test(email)) {
                  return showNotification("Enter a valid email address", "", "error");
                }
                setStepGift(2);
              }}
              myButton
              withoutTabbar
            />
            
            </>}
          
          
        </>
      ) : stepGift === 2 ? (
        <>
          {giftCard.type === "denomination" && <Back callback={() => {
              setStepGift(1)
            }}/>}
          {giftCard.type === "denomination" ? <Section header="Select product">
              {prices ? prices.length > 0 ? <>
                {prices.map((product) => {
                    return (
                        <VibrateModule>
                          <Cell description={(Number(product.billAmount) * Number(giftCard.fee)).toFixed(2)+" USD"} onClick={async () => {
                              await setSelectedPrice(product)
                              setStepGift(3);
                          }}>
                              {product.title}
                        </Cell>
                        </VibrateModule>
                    )
                })}
                </> : 
                <>
                  <Placeholder style={{margin:"30px 0"}} description={"Denomination list is empty"}></Placeholder>
                </> :  <div style={{display:"flex", alignItems:"center", justifyContent:"center", width:"100%" , height: "120px"}}>
                        <Spinner />
                </div>}
          </Section> : <>
              {fieldsRange ? fieldsRange.length > 0 ? <>
                {fieldsRange.map((field) => {
                    return (
                      <Section header={field.name}>
                        <Input
                          value={field.value}
                          name={field.name}
                          onChange={(e) => handleChangeRenageValue(e)}
                          placeholder="Enter data"
                          className="input"
                        />
                      </Section>
                    )
                })}
                </> : 
                <>
                  <Placeholder style={{margin:"30px 0"}} description={"fields list is empty"}></Placeholder>
                </> :  <div style={{display:"flex", alignItems:"center", justifyContent:"center", width:"100%" , height: "120px"}}>
                        <Spinner />
                </div>}


                {Number(fieldsRange.find(field => String(field.name).toLowerCase() === "amount").value) > 0 && (
                <MainButton
                  text={"Next"}
                  func={() => {
                    // Флаг для отслеживания валидности всех полей
                    let isValid = true;

                    fieldsRange.forEach((field) => {
                      const value = field.value;

                      // Проверка для полей типа decimal
                      if (field.type === "decimal") {
                        const validation = JSON.parse(field.validationRule);
                        const numValue = parseFloat(value);
                        
                        if (numValue < validation.min || numValue > validation.max) {
                          showNotification(
                            "Error",
                            `Value for ${field.name} should be between ${validation.min} and ${validation.max}`,
                            "error"
                          );
                          isValid = false; // Если проверка не прошла, отмечаем как невалидное
                          return; // Прерываем проверку текущего поля
                        }
                      }

                      if (field.type === "string") {
                        const validation = JSON.parse(field.validationRule);
                        const regex = new RegExp(validation.regex);
                        if (!regex.test(value)) {
                          showNotification(
                            "Error",
                            `Invalid format for ${field.name}`,
                            "error"
                          );
                          isValid = false;
                          return;
                        }
                      }
                    });

                    if (isValid) {
                      setStepGift(3);
                    }
                  }}
                  myButton
                  withoutTabbar
                />
              )}

          </>}
          
        </>
      ) :  stepGift === 3 ? (
        <>
            <Back callback={() => {
                setStepGift(2)
                setSelectedPrice(null)
            }}/>
          <PaymentsList cards={cards} selectPayment={selectPayment} setPayment={setPayment}/>
        </>
      ) : <></>}

        <Modal
          open={modalActive}
          header={<ModalHeader after={<ModalClose onClick={() => {
              setModalActive(false)
          }}><Icon28Close style={{color: 'var(--tg-theme-text-color)'}} /></ModalClose>}>Confirm payment</ModalHeader>}
          dismissible={false}
          >
          <Placeholder
              description={giftCard.type == "denomination" ? 
                `You will buy a gift card "${giftCard?.title}" for ${Number(selectedPrice?.billAmount * giftCard?.fee).toFixed(2)} USD`
              :`You will buy a gift card "${giftCard?.title}" for ${Number(Number(fieldsRange?.find(field => String(field.name).toLowerCase() == "amount").value) * giftCard?.fee).toFixed(2)} USD`}
              header="Are you sure?"
              action={<Button onClick={() => {
                giftCard.type == "denomination" ?  handleBuyGiftCard() : postRangeValues()
              }} stretched loading={loading}>Confirm</Button>}
          >
          </Placeholder>
      </Modal>
    </>
  );
};

export default GiftCardService;
