import { List, Text } from "@telegram-apps/telegram-ui"
import InlineButtonsComponent from "./components/InlineButtons"
import { useDispatch, useSelector } from "react-redux"
import { Icon28ScanViewfinderOutline } from "@vkontakte/icons"
import { useNavigate } from "react-router-dom"
import splitNumbers from "../../helpers/splitNumbers"
import { useNotification } from "../../helpers/notification"
import { postResolveQr } from "../../api/api"
import { getUserThunk, postKycThunk } from "../../store/thunks/userThunk"
import { CryptoList } from "./components/CryptoList"
import { CardsList } from "./components/CardsList"
import {Banners} from "./components/Banners"



export const BalanceBlock = () => {

    const {user: userInfo, kyc: kycInfo} = useSelector(state => state.user)

    const { cards, loading } = useSelector(state => state.cards)
    

    const {showNotification} = useNotification()

    const nav = useNavigate()
    const dispatch = useDispatch()

    const openQrScanner = () => {
        const handleQrScan = (data) => {
            handleQrCheck(data); 
            return true
        };

        window.Telegram.WebApp.showScanQrPopup({}, handleQrScan);
    };

    const handleQrCheck = async (data) => {
        const res = await postResolveQr(data)
        if (res.status === "error") {
            console.log(res)
            window?.Telegram?.WebApp?.closeScanQrPopup()
            return showNotification("Error", res.error.message, "error")
        }

        if (userInfo?.user?.sumsubVerified === false) {
            showNotification("Error", "Go through verification", "error");
        }

        if(res.data.page === "withdraw") {
            nav(`/withdraw/${res.data.data.currency ? `?selectedCur=${String(res.data.data.currency).toLowerCase()}` : ""}${res.data.data.data.address ? `&address=${res.data.data.data.address}` : ""}${res.data.data.data.amount ? `&amount=${res.data.data.data.address}` : ""}`)
        } else if (res.data.page === "payment") {
            nav(`/payment/${res.data.id}`)
        } else if (res.data.page === "home") {
            await dispatch(getUserThunk())
        }

    }


    return (
        <>
            <List style={{paddingTop:"24px", position:"relative"}}>
                {true /*userInfo?.user?.sumsubVerified === true*/ && <Icon28ScanViewfinderOutline onClick={() => {
                    openQrScanner()
                }} width={28} height={28} className="scan__icon"/>}
                <Text className="hint" style={{display:"block"}}>Total balance</Text>
                <Text className="balance__block__bal" style={{lineHeight:"40px",fontSize: splitNumbers(Number(userInfo?.user?.totalBalance).toFixed(2)).length > 5 ? "36px" : "46px"}}><span>$</span>{userInfo?.user?.totalBalance ? splitNumbers(Number(userInfo?.user?.totalBalance).toFixed(2)) : 0}</Text>
            </List>
            
            <List>
                <InlineButtonsComponent/>
            </List>

            <CryptoList />
            <CardsList />
            <Banners />
        </>
    )
}