import { Avatar, Cell, List, Modal, Placeholder, Section, Text } from "@telegram-apps/telegram-ui"
import VibrateModule from "../../../helpers/vibrate"
import splitNumbers from "../../../helpers/splitNumbers"
import { useNavigate } from "react-router-dom"
import DollarsImg from "../../../assets/img/dollars.png"
import GiftImg from "../../../assets/img/gift.png"
import { useSelector } from "react-redux"
import { useState } from "react"
import { ModalHeader } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader"
import { ModalClose } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalClose/ModalClose"
import { Icon28Close } from "@telegram-apps/telegram-ui/dist/icons/28/close"


export const CryptoList = () => {

    const [showAllBalances,setShowAllBalances] = useState(false)
    const [modalBonuses, setModalBonuses] = useState(false)

    const nav = useNavigate()
    const {user: userInfo, kyc: kycInfo, loading, error } = useSelector(state => state.user)

    const getDollarValue = (balance, rate) => (balance ? (Number(balance) * Number(rate)).toFixed(2) : 0);

    const sortedBalances = [
        /*{ id: 'ton', name: 'Toncoin', balance: userinfo?.user?.balances?.ton, rate: rates?.ton?.usd, imgSrc: '/img/ton.png' },
        { id: 'trx', name: 'TRON', balance: userinfo?.user?.balances?.trx, rate: rates?.trx?.usd, imgSrc: '/img/trx.png' },
        { id: 'bnb', name: 'BNB', balance: userinfo?.user?.balances?.bnb, rate: rates?.bnb?.usd, imgSrc: '/img/bnb.png'}, */
        { id: 'usd', name: 'Dollars', balance: userInfo?.user.balances?.usd, rate: 1, imgSrc: DollarsImg/* '/img/usdt.png'*/ },
        //{ id: 'bbx', name: 'BITBIX', balance: userinfo?.user?.balances?.bbx, rate: 1, imgSrc: '/img/bbx.png' },
        { id: 'usd ', name: 'Bonus balance', balance: userInfo?.user.balances?.bonus, rate: 1, imgSrc: GiftImg }
    ].map(item => ({
        ...item,
        dollarValue: getDollarValue(item.balance, item.rate)
    })).sort((a, b) => b.dollarValue - a.dollarValue);

    const displayedBalances = showAllBalances ? sortedBalances : sortedBalances.slice(0, 2);


    console.log(userInfo)
    return (
        <>
        <List>
            <Section header="CRYPTO" style={{marginBottom:"0px"}}></Section>
            <div className="cards__info__block">
                {displayedBalances.map((bal) => {
                    return (
                        <VibrateModule>
                            <Cell onClick={() => {
                                if(bal.name === "Bonus balance") {
                                    return setModalBonuses(true)
                                }
                                nav("/balance/"+bal.id)
                            }} subtitle={`${bal.balance ? Number(bal.balance).toFixed(2) : 0} ${String(bal.id === "usd" ? "usdt" : bal.id).toUpperCase()}`} after={<Text>{bal.balance ? splitNumbers((Number(bal.balance) * Number(bal.rate)).toFixed(2)) : 0}{bal.name === "BITBIX" ? " BBX" : "$"}</Text>} before={<Avatar size={40} src={bal.imgSrc}/>}>
                                {bal.name}
                            </Cell>
                        </VibrateModule>
                    )
                })}

            </div>
        </List>
        <Modal
            open={modalBonuses}
            header={<ModalHeader after={<ModalClose onClick={() => {
                setModalBonuses(false)
            }}><Icon28Close style={{color: 'var(--tg-theme-text-color)'}} /></ModalClose>}>Bonus balance</ModalHeader>}
            dismissible={false}
            >
            <Placeholder
                header="You can only use the bonus balance to create a card and recharge it."
            >
            </Placeholder>
        </Modal>
        </>
    )
}