import { List, Placeholder, Section, Spinner } from "@telegram-apps/telegram-ui";
import { useNotification } from "../../helpers/notification";
import { fetchHistory } from "./api/getHistory";
import useFetch from "./hooks/useFetch";
import { useEffect, useState } from "react";
import { format } from 'date-fns'; // Importing date-fns helpers
import VibrateModule from "../../helpers/vibrate";
import TransactionData from "../../pages/components/transactions/transaction";
import Transaction from "../../pages/transaction";
import { SectionHeader } from "@telegram-apps/telegram-ui/dist/components/Blocks/Section/components/SectionHeader/SectionHeader";

export const HistoryList = () => {
  const { showNotification } = useNotification();
  const { data, loading, error } = useFetch(fetchHistory);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  
  // Helper function to group transactions by day
  const groupTransactionsByDate = (transactions) => {
    return transactions.reduce((grouped, transaction) => {
      const date = format(new Date(transaction.date), 'yyyy-MM-dd');
      if (!grouped[date]) {
        grouped[date] = [];
      }
      grouped[date].push(transaction);
      return grouped;
    }, {});
  };

  const handleTransactionClick = (transaction) => {
    setSelectedTransaction(transaction);
  };

  const handleBackButtonClick = () => {
    setSelectedTransaction(null);
  };

  useEffect(() => {
    if (error) {
      console.log(error)
      showNotification("Error", error, "error");
    }
  }, [error, showNotification]);

  if (selectedTransaction) {
    return (
      <Transaction transaction={selectedTransaction} onBack={handleBackButtonClick} />
    );
  }

  if (loading || !data) {
    return (
        <List style={{padding:"8px 18px"}} className="transactions">
            <SectionHeader style={{ paddingTop: "16px" }}>TRANSACTION HISTORY</SectionHeader>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "120px" }}>
                <Spinner />
            </div>
        </List>
      
    );
  }
  const groupedTransactions = groupTransactionsByDate(data);

  return (
    <div style={{ marginBottom: "90px" }}>
      {Object.keys(groupedTransactions).length > 0 ? (
        Object.keys(groupedTransactions).map((date) => (
          <List key={date} style={{ padding: "8px 18px" }} className="transactions">
            <SectionHeader style={{marginBottom:"0px"}}>{format(new Date(date), 'PPP')}</SectionHeader>
            {groupedTransactions[date].map((transaction, index) => (
              <VibrateModule key={transaction.id}>
                <TransactionData handleTransactionClick={handleTransactionClick} transaction={transaction} index={index} />
              </VibrateModule>
            ))}
          </List>
        ))
      ) : (
        <List style={{padding:"8px 18px"}} className="transactions">
            <SectionHeader style={{ paddingTop: "16px" }}>TRANSACTION HISTORY</SectionHeader>
            <Placeholder style={{ margin: "0 0 16px 0" }} description="Transaction list is empty"></Placeholder>
        </List>
        
      )}
    </div>
  );
};
