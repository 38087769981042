import { Avatar, Button, Cell, Headline, InlineButtons, Input, List, Modal, Placeholder, Section, Select, Spinner, Text } from "@telegram-apps/telegram-ui";
import { SectionHeader } from "@telegram-apps/telegram-ui/dist/components/Blocks/Section/components/SectionHeader/SectionHeader";
import { useNavigate, useParams } from "react-router-dom";
import Back from "../../helpers/back";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getSensetive, getCardBalance, getCardTransactions, getCardDetails, postFreeze, postUnfreeze, postDelete, topupCard } from "../../api/api";
import Loader from "../../helpers/loader";
import {Icon28Close} from "@telegram-apps/telegram-ui/dist/icons/28/close"
import splitNumbers from "../../helpers/splitNumbers";
import { InlineButtonsItem } from "@telegram-apps/telegram-ui/dist/components/Blocks/InlineButtons/components/InlineButtonsItem/InlineButtonsItem";
import { ModalClose } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalClose/ModalClose";
import { ModalHeader } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import { Icon24Delete, Icon24RemoveCircle, Icon24SnowflakeOutline, Icon24UnblockOutline, Icon28ArrowUpCircleOutline, Icon28DeleteOutlineAndroid } from '@vkontakte/icons';
import TransactionData from "../components/transactions/transaction";
import TransactionPageCard from "../components/transactions/transactionPages/transactionCard";
import VibrateModule from "../../helpers/vibrate";
import TapBar from "../components/tabbar/tabbar";
import { useNotification } from "../../helpers/notification";
import CardControllers from "./components/CardControls";
import CardImg from "./../../assets/img/card/card.png"


const TopupPage = ({onBack,id, setBalance, rates, datails}) => {
    const [currency, setCurrency] = useState("usd");
    const [amount, setAmount] = useState('');
    const [loading, setLoading] = useState(false);


    const { showNotification } = useNotification();

    const handleAmountChange = (e) => {
        setAmount(e.target.value);
    };


    useEffect(() => {
        if(window.history.length > 0) {
          window.Telegram.WebApp.BackButton.onClick(onBack);
          window.Telegram.WebApp.BackButton.show();
      
          return () => {
            window.Telegram.WebApp.BackButton.hide();
            window.Telegram.WebApp.BackButton.offClick(onBack);
          };
        }
      }, []);


    const handleSubmit = async () => {
        

        if (!amount || isNaN(amount) || amount <= 0) {
            showNotification("Error", "Please enter a valid amount",  "error")
            return;
        }

        if(String(datails.brand).toLowerCase() == "visa" && amount < 10) {
            showNotification("Error", "Minimum amount 10 USD",  "error")
            return;
        }

        setLoading(true);
        try {
            const res = await topupCard(id,{ currency, amount: Number(amount) });
            if (res.status === "success") {
                showNotification("Success", "Top-up request submitted successfully!",  "success")
                setAmount('');
                onBack()
                setBalance(res.data.cardBalance)
            } else {
                console.error(res)
                showNotification("Error", res.error.message,  "error")
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <List>
        <Section footer={ String(datails.brand).toLowerCase() == "visa" && "Minimum top-up 10 USD"}>
            <SectionHeader style={{padding: "16px"}}>TOP-UP CARD BALANCE</SectionHeader>
            <List>

            <div style={{marginBottom:"16px"}}>
                <div style={{paddingBottom:"5px"}}>
                    <Text>Currency</Text>
                </div>
                
                <Select value={currency} onChange={(e) => setCurrency(e.target.value)}>
                    {/*<option value="ton">TON</option>*/}
                    <option value="usd">USDT</option>
                    {/*<option value="bnb">BNB</option>
                    <option value="trx">TRX</option>*/}
                </Select>
            </div>
            
            <div>
                <div style={{paddingBottom:"5px"}}>
                    <Text>Amount (USD)</Text>
                </div>
                
                <Input
                    placeholder={`Enter amount in USD`}
                    value={amount}
                    onChange={handleAmountChange}
                    type="text"
                />
            </div>

            <Cell 
                style={{paddingLeft: "0px", paddingRight: "0px", paddingTop:"0px", paddingBottom:"0px"}}
                after={<>
                <Text style={{whiteSpace:"nowrap"}}>{isNaN(amount) ? 0 : ((Number(amount) * 1.04 / (currency === "ton" ? Number(rates?.ton?.usd) : 1)) - (Number(amount) / (currency === "ton" ? Number(rates?.ton?.usd) : 1))).toFixed(2)} {String(currency).toUpperCase()}</Text>
                </>}
                description={"When replenishing the card balance"}>
                    Commission 4%
                </Cell>
            <Button
                onClick={handleSubmit}
                
                loading={loading}
                stretched
                style={{ marginTop: "16px", marginBottom:"16px" }}
                disabled={loading}
            >Pay {isNaN(amount) ? 0 : (Number(amount) * 1.04 / (currency === "ton" ? Number(rates?.ton?.usd) : 1)).toFixed(2)} {String(currency).toUpperCase()}</Button>
            </List>
        </Section>
        </List>
    );
};



const Card = ({me, rates}) => {
    const { id } = useParams();
    const nav = useNavigate();
    const { showNotification } = useNotification();

    const [card, setCard] = useState(null);
    const [sensetive, setSensetive] = useState(null);
    const [detailsVisible, setDetailsVisible] = useState(false);
    const [balance, setBalance] = useState(null);
    const [transactions, setTransactions] = useState(null);
    const [selectedTransaction, setSelectedTransaction] = useState(null);

    const [requestError, setRequestError] = useState(false)
 
    const [statusTopup,setStatusTopup] = useState(false)

    const [modelDelete, setModalDelete] = useState(false)
    const [freezeModal, setFreezeModal] = useState(false)

    const [datails, setDetails] = useState(null)

    const [loading, setLoading] = useState(false)

    const [imageLoaded, setImageLoaded] = useState(false); 
    const [imageSrc, setImageSrc] = useState(null);


    useEffect(() => {
        if(datails === null) return 
        const img = new Image();
        let string = CardImg
        /*if(datails?.type?.includes("google")) {
            string = "/card/gp.png"
        } else if(datails?.type?.includes("apple")) {
            string = "/card/ap.png"
        } else if (datails?.type?.includes("virtual")) {
            string = "/card/vr.png"
        }*/
        img.src = string
        img.onload = () => {
            console.log("Image preloaded");
            setImageSrc(img.src);
            setImageLoaded(true); 
        };
    }, [datails]);

    async function fetchSensetiveData() {
        if (requestError) return; 
        const res = await getSensetive(id);
        if (res.status === "error" && !requestError) {
            console.error(res)
            setRequestError(true)
            nav("/")
            return showNotification("Error", res.error.message, "error");
        }
        setSensetive(res.data);
    }

    async function fetchDetails() {
        if (requestError) return; 
        const res = await getCardDetails(id);
        if (res.status === "error" && !requestError) {
            console.error(res)
            setRequestError(true)
            nav("/")
            return showNotification("Error", res.error.message, "error");
        }
        setDetails(res.data);
    }

    async function fetchBalance() {
        if (requestError) return; 
        const res = await getCardBalance(id);
        if (res.status === "error" && !requestError) {
            console.error(res)
            setRequestError(true)
            nav("/")
            return showNotification("Error", res.error.message, "error");
        }
        setBalance(res.data.cardBalance);
    }

    async function fetchTransactions() {
        if (requestError) return; 
        const res = await getCardTransactions(id);
        if (res.status === "error" && !requestError) {
            console.error(res)
            setRequestError(true)
            nav("/")
            return showNotification("Error", res.error.message, "error");
        }
        setTransactions(res.data);
    }

    const handleToggleDetails = () => {
        setDetailsVisible(prev => !prev);
    };


    const handleCopyCardNumber = () => {
        try {
        navigator.clipboard.writeText(sensetive?.cardNumber)
            .then(() => {
                showNotification("Success", "Card number copied to clipboard",  "success")
            })

        } catch (e) {
            console.log(e)
            return showNotification("Error", "Error while copying", "error") 
          }
    };

    const handleTransactionClick = (transaction) => {
        setSelectedTransaction(transaction);
    };

    const handleBackButtonClick = () => {
        setSelectedTransaction(null); 
    };

    useEffect(() => {
        if (!id) return;
        fetchSensetiveData();
        fetchBalance();
        fetchTransactions();
        fetchDetails()
    }, [id]);

    function formatCardNumber(cardNumber) {
        return String(cardNumber).replace(/(\d{4})(?=\d)/g, '$1 ');
    }

    

    const deleteCard = async () => {
        try {
            setLoading(true)
            const res = await postDelete(id)
            if (res.status === "error") {
                console.error(res)
                return showNotification("Error", res.error.message, "error");
              }
            setModalDelete(false)
            nav("/")
            return showNotification("Success", "", "success");
        } finally {
            setLoading(false)
        }
    }

    const freezeCard = async () => {
        try {
            setLoading(true)
            const res = await postFreeze(id)
            if (res.status === "error") {
                console.error(res)
                return showNotification("Error", res.error.message, "error");
              }
            await fetchDetails()
            setFreezeModal(false)
            return showNotification("Success", "", "success");
            return toast.success("Successfully")
        } finally {
            setLoading(false)
        }
    }

    const unfreezeCard = async () => {
        try {
            setLoading(true)
            const res = await postUnfreeze(id)
            if (res.status === "error") {
                console.error(res)
                return showNotification("Error", res.error.message, "error");
              }
            await fetchDetails()
            setFreezeModal(false)
            return showNotification("Success", "", "success");
            return toast.success("Successfully")
        } finally {
            setLoading(false)
        }
    }

    if (!imageLoaded || sensetive === null || balance === null || datails === null) return <Loader />;

    if (selectedTransaction) {
        return (
            <TransactionPageCard
                transaction={selectedTransaction}
                onBack={handleBackButtonClick}
                cur={datails.currency === "USD" ? "$" : "€"} 
            />
        );
    }
    return (
        <>
        <TapBar active={"cards"} me={me}/>
        <div style={{paddingBottom:"76px"}}>
        
        {statusTopup ? <TopupPage rates={rates} id={id} onBack={() => setStatusTopup(false)} datails={datails} setBalance={setBalance}/> : <>
            <Back />
            <Placeholder
                header={<div className="balance__block__amount">
                    <Text className="balance__block__bal"style={{fontSize: splitNumbers(Number(balance).toFixed(2)).length > 5 ? "36px" : "46px"}}><span>{datails.currency === "USD" ? "$" : "€"}</span>{splitNumbers(Number(balance).toFixed(2))}</Text>
                    {datails.actions?.includes("topup") && <VibrateModule><svg onClick={() => setStatusTopup(true)}   width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" className="icon_svg" clip-rule="evenodd" d="M12 23.5C18.0751 23.5 23 18.5751 23 12.5C23 6.42487 18.0751 1.5 12 1.5C5.92487 1.5 1 6.42487 1 12.5C1 18.5751 5.92487 23.5 12 23.5ZM12.9 7.5C12.9 7.00294 12.4971 6.6 12 6.6C11.5029 6.6 11.1 7.00294 11.1 7.5V11.6H7C6.50294 11.6 6.1 12.0029 6.1 12.5C6.1 12.9971 6.50294 13.4 7 13.4H11.1V17.5C11.1 17.9971 11.5029 18.4 12 18.4C12.4971 18.4 12.9 17.9971 12.9 17.5V13.4H17C17.4971 13.4 17.9 12.9971 17.9 12.5C17.9 12.0029 17.4971 11.6 17 11.6H12.9V7.5Z" fill="none"/>
</svg></VibrateModule>}
                </div>}
            >
                <Text className="balance__block__title">Card Balance</Text>
            </Placeholder>
            <List>
                <div className="card__block">
                    <img src={imageSrc} className="card_bg_full"/>
                       
                        <div style={{display:'flex', alignItems:"center", gap:"5px"}} className="card__block__img">
                            <img src="/img/card_l.png" style={{width:"43px", height:"43px"}}/>
                            <Text className="card__block__img_text">BFinance</Text>
                        </div>
                        <img src="/img/paypass.png" className="card__block__img_pay"/>
                            
                    {detailsVisible ? <div className="card__block__id">
                                
                                
                                    <Text className="card_number" style={{userSelect:"all"}} onClick={handleCopyCardNumber}>
                                    {!detailsVisible ? "**** **** **** ****" : formatCardNumber(sensetive?.cardNumber)}
                                </Text>
                                {/*detailsVisible && <Text className="copy"  onClick={handleCopyCardNumber}>click to copy</Text>*/}
                                <div className="card__block__id__FLEX">
                                    <div className="card__block__id__block">
                                        <Text className="card__block__id__block_t">Expire</Text>
                                        <Text className="card__block__id__block_v" style={{userSelect:"all"}}>{!detailsVisible ? "****" : sensetive?.cardExpire}</Text>
                                    </div>
                                    <div className="card__block__id__block">
                                        <Text className="card__block__id__block_t">CVV</Text>
                                        <Text className="card__block__id__block_v" style={{userSelect:"all"}}>{!detailsVisible ? "***" : sensetive?.cardCVC}</Text>
                                    </div>
                                </div>
                                

                            </div>

                            : <div className="card__block__id_show">
                                    <Text className="card_mask_show">*{String(sensetive.cardNumber).slice(12).replace(" ", "")}</Text>
                                    <div className="card__block__show" onClick={() => handleToggleDetails()}>
                                        
                                    <img src={"/img/eye.svg"}/>
                                    <Text className="card__block__show__text">{detailsVisible ? "Hide card details" : "Show card details"}</Text>
                                    
                                    </div>
                                </div> }
                            {datails.brand === "MasterCard" ? <img src="/img/Mastercard.png" className="card__block__logo"/> : <img src="/img/visa.png" className="card__block__logo"/>}
                            
                    
                </div>
            </List>
            {/*datails.actions && datails.actions.length > 0 && <InlineButtons style={{marginTop:"16px"}}>
                        {datails.actions?.includes("freeze") && <VibrateModule><InlineButtonsItem text={datails.status === "active" ? "Freeze" : datails.status === "freezed" ? "Unfreeze" : ""} onClick={() => setFreezeModal(true)}>
                        <Icon24SnowflakeOutline width={32} height={32} />

                        </InlineButtonsItem></VibrateModule>}
                        
                        {datails.actions?.includes("withdraw") && <VibrateModule><InlineButtonsItem text="Withdraw" onClick={() => {
                            return showNotification("Coming soon", "", "error");
                            return toast.error("Coming soon")
                            nav("/cards")
                            }}>
                        <Icon28ArrowUpCircleOutline width={32} height={32}  />

                        </InlineButtonsItem></VibrateModule>}
                        
                        {datails?.actions?.includes("delete") && <VibrateModule><InlineButtonsItem text="Delete" onClick={() => {
                            return showNotification("Coming soon", "", "error");
                            return toast.error("Coming soon")
                            setModalDelete(true)
                            }}>
                            <Icon28DeleteOutlineAndroid width={32} height={32} />

                        </InlineButtonsItem></VibrateModule>}
                    </InlineButtons>*/}

                    <CardControllers 
                        setFreezeModal={setFreezeModal} 
                        handleTransactionClick={handleTransactionClick} 
                        transactions={transactions} 
                        datails={datails}
                        setStatusTopup={setStatusTopup}
                    />
            </>}
            
            <Modal
                open={modelDelete}
                header={<ModalHeader after={<ModalClose onClick={() => setModalDelete(false)}><Icon28Close style={{color: 'var(--tg-theme-text-color)'}} /></ModalClose>}>Delete card</ModalHeader>}
                dismissible={false}
                >
                <Placeholder
                    description="Are you sure that you want to delete this card?"
                    header="Are you sure?"
                    action={<Button loading={loading} onClick={() => deleteCard()} stretched>Yes</Button>}
                >
                </Placeholder>
            </Modal>

            <Modal
                open={freezeModal}
                header={<ModalHeader after={<ModalClose onClick={() => setFreezeModal(false)}><Icon28Close style={{color: 'var(--tg-theme-text-color)'}} /></ModalClose>}>{datails.status === "active" ? "Freeze" : datails.status === "freezed" ? "Unfreeze" : ""} card</ModalHeader>}
                dismissible={false}
                >
                <Placeholder
                    description={`Are you sure that you want to ${datails.status === "active" ? "freeze" : datails.status === "freezed" ? "unfreeze" : ""} this card?`}
                    header="Are you sure?"
                    action={<Button loading={loading} onClick={() => {
                        if(datails.status === "active") {
                            freezeCard()
                        } else {
                            unfreezeCard()
                        }
                    }} stretched>Yes</Button>}
                >
                </Placeholder>
            </Modal>
        </div>
        </>
    );
}

export default Card;


