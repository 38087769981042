import {
    Avatar,
    Badge,
    Button,
    Cell,
    Headline,
    Input,
    List,
    Placeholder,
    Section,
    Text,
    Title
} from "@telegram-apps/telegram-ui";
import { useLocation, useNavigate } from "react-router-dom";
import Back from "../helpers/back";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { getTopup, getTransfer, postTransfer, postWithdraw } from "../api/api";
import Loader from "../helpers/loader";
import QRCode from "qrcode.react";
import { SectionHeader } from "@telegram-apps/telegram-ui/dist/components/Blocks/Section/components/SectionHeader/SectionHeader";
import { se } from "date-fns/locale/se";
import TransferPage from "./components/transactions/transactionPages/transfer";
import VibrateModule from "../helpers/vibrate";
import MainButton from "../helpers/mainbutton";
import { useNotification } from "../helpers/notification";

const Transfer = ({ me, rates }) => {
    const nav = useNavigate();
    const [topup, setTopup] = useState(null);
    const [selectedCur, setSelectedCur] = useState(null);
    const [selectedNet, setSelectedNet] = useState(null);
    const [step, setStep] = useState(1);
    const [userid, setUserid] = useState('');
    const [amount, setAmount] = useState('');

    const {showNotification} = useNotification()


    const [id, setId] = useState(null)

    const [loading, setLoading] = useState(false)

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const selectedCurFromParams = queryParams.get("selectedCur");

    useEffect(() => {
        setSelectedCur(selectedCurFromParams ? selectedCurFromParams : "");
        if(selectedCurFromParams) setStep(2)
    }, [selectedCurFromParams]);


    const handleClick = async () => {
        if (step === 1) {
            if(!selectedCur) return
            setStep(2)
        } else if (step === 2) {
            setLoading(true)
             window.Telegram.WebApp.MainButton.showProgress(true)
            const res = await getTransfer(userid)
            setLoading(false)
            window.Telegram.WebApp.MainButton.hideProgress()
            if (res.status === "error") {
                console.error(res)
                return toast.error(res.error.message);
            }
            setId(res.data)
            setStep(3);
        } else if (step === 3) {
            handleConfirm();
        }
    };
    
    /*useEffect(() => {
        const mainButton = window.Telegram.WebApp.MainButton;

        const handleClick = async () => {
            if (step === 1) {
                setStep(2)
            } else if (step === 2) {
                setLoading(true)
                 window.Telegram.WebApp.MainButton.showProgress(true)
                const res = await getTransfer(userid)
                setLoading(false)
                window.Telegram.WebApp.MainButton.hideProgress()
                if (res.status === "error") {
                    return toast.error(res.error.message);
                }
                setId(res.data)
                setStep(3);
            } else if (step === 3) {
                handleConfirm();
                setStep(4)
            }
        };

        if (step === 1 && selectedCur) {
            mainButton.offClick(handleClick);
            mainButton.disable()
            mainButton.show();
            mainButton.enable();
            mainButton.setText("Confirm");
            mainButton.onClick(handleClick);
        } else if (step === 2) {
            mainButton.offClick(handleClick);
            mainButton.disable()
            mainButton.show();
            mainButton.setText("Confirm");
            mainButton.enable();
            mainButton.onClick(handleClick);
        } else if (step === 3) {
            mainButton.offClick(handleClick);
            mainButton.disable()
            mainButton.show();
            mainButton.enable();
            mainButton.setText("Confirm");
            mainButton.onClick(handleClick);
        }

        return () => {
            mainButton.offClick(handleClick);
            mainButton.hide();
        };
    }, [selectedCur, selectedNet, step, amount, userid ]);*/

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            showNotification("Address copied successfully!", "", "success") //toast.success("Address copied successfully!");
        })
    };

    const handleAddressChange = (e) => {
        setUserid(e.target.value);
    };

    const handleAmountChange = (e) => {
        setAmount(e.target.value);
    };

    const [transfer, setResult] = useState(null)

    const handleConfirm = async () => {
        if(loading) return
        setLoading(true)
        window.Telegram.WebApp.MainButton.showProgress(true)
        try {
            const res = await postTransfer({ id: id.user.id , amount: Number(amount), currency: selectedCur === "usd" ? "usdt" : selectedCur })
            if (res.status === "error") {
                console.error(res)
                return showNotification("Error",res.error.message, "error") //toast.error();
            } else {
                showNotification("Success", "Your transaction has been sent", "success")
                setResult(true)
            }
        } finally {
            setLoading(false)
            window.Telegram.WebApp.MainButton.hideProgress()
        }
        

    };
    if(transfer === true) return <TransferPage transfer={{amount: amount, user: id.user, cur: selectedCur}} handleClose={() => nav("/")}/>
    return (
        <List>
            <Back callback={() => {
                nav("/online/payments?tgWebAppStartParam=category_Transfers")
            }} />
            {selectedCur === "usd" && (step > 1 && step !== 4)? (
                    <Section>
                        <Cell style={{paddingLeft:"18px", paddingRight:"18px"}} onClick={() => {
                            setSelectedCur(null)
                            setStep(1);
                        }}  subtitle={`${Number(me?.user?.balances?.usd).toFixed(2)} USDT`} after={<Text>{(Number(me?.user?.balances?.usd)).toFixed(2)}$</Text>} before={<Avatar src="/img/usdt.png" />}>
                            Dollars
                        </Cell>
                    </Section>
                ) : (step > 1 && step !== 4) && selectedCur && <Section>
                <Cell style={{paddingLeft:"18px", paddingRight:"18px"}} onClick={() => {
                    setSelectedCur(null)
                    setStep(1);
                }}  subtitle={`${Number(me?.user?.balances?.[selectedCur === "usd" ? "usdt" : selectedCur]).toFixed(2)} ${String(selectedCur).toUpperCase()}`} after={<Text>{ selectedCur === "bbx" ? Number(me?.user?.balances?.bbx).toFixed(2) : (Number(me?.user?.balances?.[selectedCur]) * Number(rates?.[selectedCur]?.usd)).toFixed(2)}{selectedCur === "bbx" ? " BBX": " $"}</Text>} before={<Avatar src={`/img/${selectedCur}.png`} />}>
                    {selectedCur === "ton" ? "Toncoin": selectedCur === "bnb" ? "Binance Coin": selectedCur === "trx" ? "TRON": "BITBIX"}
                </Cell>
            </Section>}

            {step === 3 && id && <Section>
                <Cell style={{paddingLeft:"18px", paddingRight:"18px"}} onClick={() => {
                    setId(null)
                    setStep(2);
                }} subtitle={`${id?.user?.username ? "@" +id?.user?.username : ""}`} before={<Avatar src={`${id.user.photoB64}`} acronym={String(id.user.name).charAt(0)} />}>
                    {id?.user?.name ? id?.user?.name :  "User"}
                </Cell>
            </Section>}

                
                {step === 1 ? (
                    <>
                    <Section>

                        <VibrateModule>
                        <SectionHeader style={{ paddingTop: "16px" }}>CHOOSE CURRENCY FOR TRANSFER</SectionHeader>
                        {/*<Cell onClick={() => {
                            if (selectedCur === "ton") {
                                return setSelectedCur(null);
                            }
                            setSelectedCur("ton");
                        }} titleBadge={selectedCur === "ton" && <Badge type="dot" />} subtitle={`${me?.user?.balances?.ton ? Number(me?.user?.balances?.ton).toFixed(2) : 0} TON`} after={<Text>${me?.user?.balances?.ton ? (Number(me?.user?.balances?.ton * Number(rates?.ton?.usd))).toFixed(2) : 0}</Text>} before={<Avatar src="/img/ton.png" />}>
                            Toncoin
                        </Cell>*/}
                        </VibrateModule>
                        <VibrateModule>
                        <Cell subtitle={`${me?.user?.balances?.usd ? Number(me?.user?.balances?.usd).toFixed(2): 0} USD`} onClick={() => {
                            if (selectedCur === "usd") {
                                return setSelectedCur(null);
                            }
                            setSelectedCur("usd");
                        }} titleBadge={selectedCur === "usd" && <Badge type="dot" />}  after={<Text>{me?.user?.balances?.usd ? (Number(me?.user?.balances?.usd)).toFixed(2): 0}$</Text>} before={<Avatar src="/img/usdt.png" />}>
                        Dollars
                        </Cell>
                        </VibrateModule>
                        { /* <Cell onClick={() => {
                            if (selectedCur === "trx") {
                                return setSelectedCur(null);
                            }
                            setSelectedCur("trx");
                        }}  titleBadge={selectedCur === "trx" && <Badge type="dot" />} subtitle={`${me?.user?.balances?.trx ? Number(me?.user?.balances?.trx).toFixed(2): 0} TRON`} after={<Text>${me?.user?.balances?.trx ? (Number(me?.user?.balances?.trx * Number(rates?.trx?.usd))).toFixed(2) : 0}</Text>} before={<Avatar src="/img/trx.png"/>}>
                            TRON
                        </Cell>
                        <Cell onClick={() => {
                            if (selectedCur === "bnb") {
                                return setSelectedCur(null);
                            }
                            setSelectedCur("bnb");
                        }} titleBadge={selectedCur === "bnb" && <Badge type="dot" />} subtitle={`${me?.user?.balances?.bnb ? Number(me?.user?.balances?.bnb).toFixed(2) : 0} BNB`} after={<Text>${me?.user?.balances?.bnb ? (Number(me?.user?.balances?.bnb * Number(rates?.bnb?.usd))).toFixed(2) : 0}</Text>} before={<Avatar src="/img/bnb.png"/>}>
                            Binance Coin
                        </Cell> */}
                        
                        
                        {/*<VibrateModule>
                        <Cell onClick={() => {
                            if (selectedCur === "bbx") {
                                return setSelectedCur(null);
                            }
                            setSelectedCur("bbx");
                        }} titleBadge={selectedCur === "bbx" && <Badge type="dot" />} subtitle={`${me?.user?.balances?.bbx ? Number(me?.user?.balances?.bbx).toFixed(2) : 0} BBX`} after={<Text>{`${me?.user?.balances?.bbx ? Number(me?.user?.balances?.bbx).toFixed(2) : 0} BBX`}</Text>} before={<Avatar src="/img/bbx.png"/>}>
                            BITBIX
                        </Cell>
                        </VibrateModule>*/}
                    </Section>
                    <MainButton text={"Confirm"} func={handleClick} myButton withoutTabbar/>
                    </>
                ) : step === 2 ? (
                    <><MainButton text={"Confirm"} func={handleClick} myButton withoutTabbar/>
                    <Section>
                        <List style={{marginBottom:"10px", marginTop:"10px"}}>
                            <Input placeholder="Username / ID" value={userid} onChange={handleAddressChange} />
                        </List>
                    </Section>
                    </>
                ) : step === 3 ? (
                    <><MainButton text={"Confirm"} func={handleClick} myButton withoutTabbar loading={loading}/>
                    <Section>
                        <List style={{marginBottom:"10px", marginTop:"10px"}}>
                            <Input placeholder="Amount" value={amount} onChange={handleAmountChange} />
                        </List>
                    </Section>
                    </>
                ): <Loader />}
                {/*<Button onClick={handleClick}>CLick</Button>*/}
        </List>
    );
};

export default Transfer;
