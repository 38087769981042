import { createSlice } from "@reduxjs/toolkit";
import { getUserThunk, postKycThunk } from "../thunks/userThunk";

const initialState = {
  user: null,
  loading: true,
  error: "",
  kyc: null
};

const userReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
    setKyc(state, action) {
      state.kyc = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(getUserThunk.pending, (state) => {
        state.loading = true; 
      })
      .addCase(getUserThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; 
      })

      .addCase(postKycThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.kyc = action.payload;
      })
      .addCase(postKycThunk.pending, (state) => {
        state.loading = true; 
      })
      .addCase(postKycThunk.rejected, (state, action) => {
        console.log(action)
        state.loading = false;
        state.error = action.payload; 
      });
      
  },
});

export const { setUser, setKyc } = userReducer.actions;

export default userReducer.reducer;
