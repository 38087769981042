import MainButton from "../../../../../helpers/mainbutton"
import { Avatar, Cell, Input, Section, Placeholder, Modal, Button, List } from "@telegram-apps/telegram-ui"
import MasterCardImg from "../../../../../assets/img/mc.png"
import VisaImg from "../../../../../assets/img/vis.png"
import Back from "../../../../../helpers/back"
import VibrateModule from "../../../../../helpers/vibrate"
import { ModalHeader } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader"
import { Icon28Close } from "@telegram-apps/telegram-ui/dist/icons/28/close"
import { ModalClose } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalClose/ModalClose"
import toast from "react-hot-toast"
import { getPhoneTopupProducts, postPhoneTopup, postPhoneTopupPayment } from "../../../../../api/api"
import { useState } from "react"
import TransferPage from "../../../transactions/transactionPages/transfer"
import Loader from "../../../../../helpers/loader"
import { useNotification } from "../../../../../helpers/notification"

const PhoneTopup = ({cards, loading, getSelectedService, step, setStep, setSelectedService, setLoading}) => {

      

    const [topupConfirmrm, setTopupConfirm] = useState(false)

    const [payment, setPayment] = useState(null)
    const [phoneNumber, setPhoneNumber] = useState("")

    const [phoneAmount, setPhoneAmount] = useState()

    const {showNotification} = useNotification()

    const [productsPhoneTopup, setProductsPhoneTopup] = useState(null)
    const [selectedProduct, setSelectedProduct] = useState(null)

    async function handlePhoneTopup(prod) {
        let product = prod || selectedProduct
        console.log(loading)
        if(loading === true) return 
        setLoading(true)
        try {
            let amount = phoneAmount
            if(product.min.receiveValue == product.max.receiveValue) amount = product.max.receiveValue
            if(!amount || Number(amount) < 0) return
            if((product.min.receiveValue > Number(amount)) || (Number(amount) > product.max.receiveValue)) return showNotification("Error", `Min amount ${product.min.receiveValue}, max: ${product.max.receiveValue}`, "error") 
            const res = await postPhoneTopupPayment({sku: product.skuCode, amount: Number(amount), currency: product.min.receiveCurrencyIso, phone: phoneNumber.replace("+", "").trim()})
            if (res.status === "error") {
                console.error(res)
                return showNotification("Error",res.error.message, "error") //toast.error();
            }
            setTopupConfirm(res.data)
            setStep(5)    
        } finally {
            setLoading(false)
        }
        
    }


    const [modalActive, setModalActive] = useState(false)

    const selectPayment = async () => {
        setModalActive(true)
    }



    async function handleSendPhoneNumber() {
        if(loading === true) return 
        setLoading(true)
        try {
            if(!phoneNumber.trim() || phoneNumber.trim() == "") return
            const res = await getPhoneTopupProducts({phone: phoneNumber.replace("+", "").trim()})
            if (res.status === "error") {
                console.error(res)
                return showNotification("Error",res.error.message, "error") //toast.error();
            }
            setProductsPhoneTopup(res.data)
            if(res.data.length === 1) {
                setSelectedProduct(res.data[0])
                if(res.data[0].min.receiveValue == res.data[0].max.receiveValue) {
                    return handlePhoneTopup()
                }
                return setStep(4)
            } else {
                return setStep(3)
            }
        } finally {
            setLoading(false)
        }
        
    }

    
    

    const [result, setResult] = useState(null)
    
    

    async function handleSubmitOrder() {
        if(loading === true) return 
        setLoading(true)
        try {
            let amount = topupConfirmrm.send.gbp.value
            const res = await postPhoneTopup({payData: payment.value, payType: payment.type ,sku: selectedProduct.skuCode, amount: Number(amount), phone: phoneNumber.replace("+", "").trim()});
            if (res.status === "error") {
                console.error(res)
                return showNotification("Error",res.error.message, "error") //toast.error();
            }
            setResult(res.data)
            setModalActive(false)
            return showNotification("Success","Phone topped up successfully", "success") 
        } finally {
            setLoading(false)
        }
    }

    

    const getSelectedProduct = () => {
        return (
            <>
                {selectedProduct && <Cell style={{margin:"6px 0"}} before={<Avatar size={40} acronym={String(selectedProduct.provider.name).charAt(0)} src={selectedProduct.provider.logo} />} description={selectedProduct.description}>
                    {selectedProduct.provider.name}
                </Cell>}
            </>
        )
    }


 


    if(result) return <TransferPage handleClose={() => {
        setStep(2)
        setResult(null)
    }} transfer={{amount:topupConfirmrm?.send?.value, cur: topupConfirmrm?.send?.currency }} head={"Phone topup"} info={"You have successfully completed the payment!"}/>
    return (
        <List>
            {step === 2 ? <>
            <Back callback={() => {
                setStep(1)
                setSelectedService(null)
            }}/>
            {getSelectedService()}

            <Section>
                <Input
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="Enter phone number"
                    className="input"
                />
                {/*<div onClick={() => handleSendPhoneNumber()}>dasda</div>*/}
                
            </Section>
            {!modalActive && <MainButton text={"Confirm"} func={() => handleSendPhoneNumber()} loading={loading} myButton withoutTabbar/>}
            </> : step === 3 ? <>
            <Back callback={() => {
                setStep(2)
            }}/>
            {getSelectedService()}

            <>
                {productsPhoneTopup && productsPhoneTopup.length > 0 ? <Section header="Select product">
                    {productsPhoneTopup.map((product) => {
                        return (
                            <VibrateModule>
                            <Cell before={<Avatar size={40} acronym={String(product.provider.name).charAt(0)} src={product.provider.logo} />} description={product.description} onClick={async () => {
                                await setSelectedProduct(product)
                                if(product.min.receiveValue == product.max.receiveValue) {
                                    handlePhoneTopup(product)
                                } else {
                                    setStep(4)
                                }
                            }}>
                                {product.provider.name}
                            </Cell>
                            </VibrateModule>
                        )
                    })}
                </Section> : <Section header={"Select product"}><Placeholder style={{margin:"30px 0"}} description={"Product list is empty"}>
                </Placeholder></Section>}
            </>
            </> : step === 4 ?  <>

                <Back callback={() => {
                    setSelectedProduct(null)
                    if(productsPhoneTopup.length > 1) {
                        setStep(3)
                    } else {
                        setStep(2)
                    }
                    
                }}/>
                {getSelectedService()}
                {getSelectedProduct()}
                <Section header={`Enter amount in ${selectedProduct.min.receiveCurrencyIso}`}>
                        <Input
                            value={phoneAmount}
                            onChange={(e) => setPhoneAmount(e.target.value)}
                            placeholder="Enter amount"
                            className="input"
                        />
                </Section>
                <MainButton text={"Confirm"} func={() => handlePhoneTopup()} loading={loading} myButton withoutTabbar/>
            
            </> :  step === 5 ?  <>
                <Back callback={() => {
                    
                    setModalActive(false)
                    if(selectedProduct.min.receiveValue == selectedProduct.max.receiveValue) {
                        setSelectedProduct(null)
                        if(productsPhoneTopup.length > 1) {
                            return setStep(3)
                        } else {
                            return setStep(2)
                        }
                    }
                    return setStep(4)
                    
                }}/>
                {getSelectedService()}
                {getSelectedProduct()}

                    <Section header="Select payment method">
                        {/*<VibrateModule>
                        <Cell subhead="Crypto" onClick={() => {
                            return showNotification("Error", "Temporarily unavailable", "error")
                            selectPayment()
                            setPayment({
                                type: "crypto",
                                value: "ton"
                            })
                        }} before={<Avatar size={48} src={'/img/ton.png'}/>}>
                            TON
                        </Cell>
                        </VibrateModule>*/}
                    </Section>
                    <Section >
                    <VibrateModule>
                        <Cell onClick={() => {
                            selectPayment()
                            setPayment({
                                type: "crypto",
                                value: "usdt"
                            })
                        }} subhead="Crypto" before={<Avatar size={48} src={'/img/usdt.png'}/>}>
                            Dollars
                        </Cell>
                        </VibrateModule>
                    </Section>
                    {cards.length > 0 ? cards?.map(card => {
                        return (
                            <Section>
                                <VibrateModule>
                                <Cell disabled subhead={"Card"} before={<Avatar size={48} src={String(card.brand).toLowerCase() === "mastercard" ? MasterCardImg : String(card.brand).toLowerCase() === "visa" ? VisaImg  : ""} acronym={card.brand.charAt(0)} />} 
                                onClick={() => {
                                    return
                                    selectPayment()
                                    setPayment({
                                        type: "card",
                                        value: card.id
                                    })
                                }}>
                                    {card.brand} {String(card.mask).slice(13).replace(" ", "")}
                                </Cell>
                                </VibrateModule>
                            </Section>
                        )
                    }) : "" }

              

                </>  : <Loader /> }

                <Modal
                open={topupConfirmrm && modalActive}
                header={<ModalHeader after={<ModalClose onClick={() => {
                    setModalActive(false)
                }}><Icon28Close style={{color: 'var(--tg-theme-text-color)'}} /></ModalClose>}>Confirm payment</ModalHeader>}
                dismissible={false}
                >
                <Placeholder
                    description={`You will send ${Number(topupConfirmrm?.send?.value).toFixed(2)} ${topupConfirmrm?.send?.currency} and receive ${Number(topupConfirmrm?.receive?.value).toFixed(2)} ${topupConfirmrm?.receive?.currency}`}
                    header="Are you sure?"
                    action={<Button onClick={() => {
                        handleSubmitOrder()
                    }} stretched loading={loading}>Confirm</Button>}
                >
                </Placeholder>
            </Modal>
        </List>
    )
}

export default PhoneTopup