import {
    Avatar,
    Cell,
    InlineButtons,
    List,
    Placeholder,
    Section,
    Text,
    Title
} from "@telegram-apps/telegram-ui";
import { useNavigate, useParams } from "react-router-dom";
import Back from "../helpers/back";
import toast from "react-hot-toast";
import Loader from "../helpers/loader";
import { getHistoryCur } from "../api/api";
import { format } from "date-fns";
import Transaction from "./transaction";
import { SectionHeader } from "@telegram-apps/telegram-ui/dist/components/Blocks/Section/components/SectionHeader/SectionHeader";
import { InlineButtonsItem } from "@telegram-apps/telegram-ui/dist/components/Blocks/InlineButtons/components/InlineButtonsItem/InlineButtonsItem";
import { useEffect, useState } from "react";
import splitNumbers from "../helpers/splitNumbers";
import TransactionData from "./components/transactions/transaction";
import VibrateModule from "../helpers/vibrate";
import { useNotification } from "../helpers/notification";
import DollarsImg from "./../assets/img/dollars.png"
import { postKycThunk } from "../store/thunks/userThunk";
import { useDispatch } from "react-redux";

const Balance = ({ me, rates }) => {
    const dispatch = useDispatch()
    const nav = useNavigate();
    const { type } = useParams();

    const {showNotification} = useNotification()

    const [transactions, setTransactions] = useState(null);
    const [balance, setBalance] = useState(0);
    const [usdBalance, setUsdBalance] = useState(0);
    const [selectedTransaction, setSelectedTransaction] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getHistoryCur(type);
                if (res.success === false) {
                    return showNotification("Error", `${res.error} attempts left`, "error") 
                    return toast.error(res.error);
                }
                setTransactions(res.data);

                if (type === "ton") {
                    setBalance(me?.user?.balances?.ton);
                    setUsdBalance((me?.user?.balances?.ton * rates?.ton?.usd).toFixed(2));
                } else if (type === "usd") {
                    setBalance(me?.user?.balances?.usd);
                    setUsdBalance(me?.user?.balances?.usd);
                } else if (type === "bbx") {
                    setBalance(me?.user?.balances?.bbx);
                    setUsdBalance((me?.user?.balances?.bbx * (rates?.bbx?.usd ?rates?.bbx?.usd : 1) ).toFixed(2));
                } else if (type === "trx") {
                    setBalance(me?.user?.balances?.trx);
                    setUsdBalance((me?.user?.balances?.trx * (rates?.trx?.usd ?rates?.trx?.usd : 1) ).toFixed(2));
                } else if (type === "bnb") {
                    setBalance(me?.user?.balances?.bnb);
                    setUsdBalance((me?.user?.balances?.bnb * (rates?.bnb?.usd ?rates?.bnb?.usd : 1) ).toFixed(2));
                }
            } catch (e) {
                console.log(e);
                return showNotification("Error", "Error when receiving transactions", "error") 
                return toast.error("Error when receiving transactions");
            }
        };

        fetchData();
    }, [type, me, rates]);

    const handleTransactionClick = (transaction) => {
        setSelectedTransaction(transaction);
    };

    const handleBackButtonClick = () => {
        setSelectedTransaction(null);
    };

    if (selectedTransaction) {
        return (
            <Transaction
                transaction={selectedTransaction}
                onBack={handleBackButtonClick}
            />
        );
    }

    if (transactions === null) return <Loader />;
    return (
        <>
            <Back />
            <Placeholder
                description={type.toUpperCase() === "BBX" ? <></>  :<Text className="balance__block__hint__bal">{splitNumbers(Number(balance).toFixed(2))} {type.toUpperCase()}</Text>}
                header={<div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                    <Text className="balance__block__title">{type.toUpperCase()} Balance</Text>
                    {type.toUpperCase() === "BBX" ? <Text className="balance__block__bal__bal">{splitNumbers(Number(balance).toFixed(2))} {type.toUpperCase()}</Text> : <Text className="balance__block__bal__bal">{Number(usdBalance).toFixed(type === "bnb" ? 6 : 2)}$</Text>}
                </div>}
            >
                <img src={type === "usd" ? DollarsImg : `/img/${type === "usd" ? "usdt" : type}.png`} className="balance__block__img__bal" />
            </Placeholder>
            <InlineButtons style={{ marginBottom: "16px" }}>
                <VibrateModule>
                <InlineButtonsItem  className="balance__button" text="Send" onClick={() => {
                   // if(/*Number(me?.user?.balances?.usd) <= 0 && */ me?.user?.sumsubVerified === false) return showNotification("Error", "Not enough balance", "error"); 
                    //if(!me?.user?.haveAccess) return showNotification("Error","You're on the wait list", "error") 
                    if(me?.user?.sumsubVerified === false) return dispatch(postKycThunk())
                    nav(`/withdraw?selectedCur=${type}`)
                }}>
                <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                             <path fill-rule="evenodd" className="icon_svg" clip-rule="evenodd" d="M16.5 30C24.232 30 30.5 23.732 30.5 16C30.5 8.26801 24.232 2 16.5 2C8.76801 2 2.5 8.26801 2.5 16C2.5 23.732 8.76801 30 16.5 30ZM17.1498 9.37729C16.98 9.20016 16.7453 9.1 16.5 9.1C16.2547 9.1 16.02 9.20016 15.8502 9.37729L10.8502 14.5947C10.5063 14.9535 10.5184 15.5233 10.8773 15.8672C11.2362 16.2111 11.8059 16.199 12.1498 15.8401L15.6 12.2399V22C15.6 22.4971 16.0029 22.9 16.5 22.9C16.9971 22.9 17.4 22.4971 17.4 22V12.2399L20.8502 15.8401C21.1941 16.199 21.7638 16.2111 22.1227 15.8672C22.4816 15.5233 22.4937 14.9535 22.1498 14.5947L17.1498 9.37729Z" fill="none"/>
                        </svg>
                </InlineButtonsItem>
                </VibrateModule>
                <VibrateModule>
                <InlineButtonsItem className="balance__button" text="Receive" onClick={() => nav(`/topup?selectedCur=${type}`)}>
                    <svg width="33" style={{ rotate: "180deg" }} height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                             <path fill-rule="evenodd" className="icon_svg" clip-rule="evenodd" d="M16.5 30C24.232 30 30.5 23.732 30.5 16C30.5 8.26801 24.232 2 16.5 2C8.76801 2 2.5 8.26801 2.5 16C2.5 23.732 8.76801 30 16.5 30ZM17.1498 9.37729C16.98 9.20016 16.7453 9.1 16.5 9.1C16.2547 9.1 16.02 9.20016 15.8502 9.37729L10.8502 14.5947C10.5063 14.9535 10.5184 15.5233 10.8773 15.8672C11.2362 16.2111 11.8059 16.199 12.1498 15.8401L15.6 12.2399V22C15.6 22.4971 16.0029 22.9 16.5 22.9C16.9971 22.9 17.4 22.4971 17.4 22V12.2399L20.8502 15.8401C21.1941 16.199 21.7638 16.2111 22.1227 15.8672C22.4816 15.5233 22.4937 14.9535 22.1498 14.5947L17.1498 9.37729Z" fill="none"/>
                        </svg>
                </InlineButtonsItem>
                </VibrateModule>
            </InlineButtons>
            
            <List>
            <Section>
                <SectionHeader style={{ paddingTop: "16px" }}>TRANSACTION HISTORY</SectionHeader>
                {transactions?.length > 0 ? transactions.map((transaction, index) => (
                    <TransactionData transaction={transaction} index={index} handleTransactionClick={handleTransactionClick} />
                )) : <Placeholder style={{ margin: "0 0 16px 0" }} description="Transaction list is empty"></Placeholder>}
            </Section>
            </List>
        </>
    );
};

export default Balance;
