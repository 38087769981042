import { createAsyncThunk } from "@reduxjs/toolkit"
import { getUserInfo, postKyc } from "../../api/api"



export const getUserThunk = createAsyncThunk(
    "users/getUser",
    async (ref, thunkAPI) => {
      const response = await getUserInfo(ref);
  
      if (response.status === "error") {
        console.error(response)
        return thunkAPI.dispatch(thunkAPI.rejectWithValue(response.error.message));
      }

      return response.data;
    }
);
  

export const postKycThunk = createAsyncThunk(
  "users/postKyc",
  async (ref, thunkAPI) => {
    const response = await postKyc();

    if (response.status === "error") {
      console.error(response)
      return thunkAPI.rejectWithValue(response.error.message);
    }

    return response.data;
  }
);