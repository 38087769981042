import { useNavigate, useParams } from "react-router-dom"
import Back from "../../helpers/back"
import { useEffect, useState } from "react"
import Loader from "../../helpers/loader"
import { useNotification } from "../../helpers/notification"
import { getCards, getPaymentData } from "../../api/api"
import { Avatar, Button, Cell, List, Modal, Placeholder, Section, Text } from "@telegram-apps/telegram-ui"
import VibrateModule from "../../helpers/vibrate"
import { Icon28Close } from "@telegram-apps/telegram-ui/dist/icons/28/close"
import { ModalHeader } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader"
import { ModalClose } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalClose/ModalClose"
import MasterCardImg from "./../../assets/img/mc.png"
import VisaImg from "./../../assets/img/vis.png"


const PaymnetQR = ({me, setMe}) => {
    const {id} = useParams()
    const nav = useNavigate()

    const [paymentData, setPaymentData] = useState(null)
    const { showNotification } = useNotification();

    const [modalActive, setModalActive] = useState(false)
    const [payment, setPayment] = useState(null)
    const [loading, setLoading] = useState()

    const [cards, setCards] = useState(null)

    const selectPayment = async () => {
        setModalActive(true)
    }

    async function fetchCards() {
        const res = await getCards()
        if (res.status === "error") {
            console.error(res)
            return showNotification("Error",res.error.message, "error") //toast.error();
        }
        setCards(res.data)
    }

    async function fetchPayment() {
        /*const res = await getPaymentData()
        if (res.status === "error") {
            return showNotification("Error", res.error.message, "error");
        }
        setPaymentData(res.data);*/
        setPaymentData(true)
    }

    useEffect(() => {
        if(!id) return
        async function getData() {
            await fetchPayment()
            await fetchCards()
        }
        getData()
    }, [id])

    const handleSubmitPayment = async () => {
        setLoading(true)
        try {
            const res = await getPaymentData()
            if (res.status === "error") {
                console.error(res)
                return showNotification("Error", res.error.message, "error");
            }
            nav("/")
            setModalActive(false)
            return showNotification("Success","Payment completed successfully", "success") 
        } finally {
            setLoading(false)
        }
    }

    if(paymentData === null || cards === null) return <Loader />

    return (
        <div className="transaction-overlay">
            <Back callback={() => nav("/")} />
            <List>
                <Cell className="transaction__user" before={<Avatar size={40} acronym={paymentData?.action?.charAt(0)} src={""} />}>
                    Payment at <span className="transaction__user__name">{paymentData?.name || ""}</span>
                </Cell>
                <Text className={`transaction__amount`}>
                    <span style={{fontSize: String(Number(Math.abs(paymentData?.amount)).toFixed(2)).length > 6 ? "34px" : String(Number(Math.abs(paymentData?.amount)).toFixed(2)).length > 9 ? "24px" : "46px" }}>{String(paymentData?.amount).toUpperCase()} {String(paymentData?.currency).toUpperCase()}</span>
                </Text>
                <Section header="Payment method">
                       {/* <VibrateModule>
                        <Cell subhead="Crypto" onClick={() => {
                            selectPayment()
                            setPayment({
                                type: "crypto",
                                value: "ton"
                            })
                        }} before={<Avatar size={48} src={'/img/ton.png'}/>}>
                            TON
                        </Cell>
                        </VibrateModule>*/}
                        </Section>
                    <Section>
                    <VibrateModule>
                        <Cell onClick={() => {
                            selectPayment()
                            setPayment({
                                type: "crypto",
                                value: "usdt"
                            })
                        }} subhead="Crypto" before={<Avatar size={48} src={'/img/usdt.png'}/>}>
                            Dollars
                        </Cell>
                        </VibrateModule>
                    </Section>
                    {cards.length > 0 ? cards?.map(card => {
                        return (
                            <Section>
                                <VibrateModule>
                                <Cell disabled subhead={"Card"} before={<Avatar size={48} src={String(card.brand).toLowerCase() === "mastercard" ? MasterCardImg : String(card.brand).toLowerCase() === "visa" ? VisaImg  : ""} acronym={card.brand.charAt(0)} />} 
                                onClick={() => {
                                    return
                                    selectPayment()
                                    setPayment({
                                        type: "card",
                                        value: card.id
                                    })
                                }}>
                                    {card.brand} {String(card.mask).slice(13).replace(" ", "")}
                                </Cell>
                                </VibrateModule>
                            </Section>
                        )
                    }) : "" }
            </List>

            <Modal
                open={modalActive}
                header={<ModalHeader after={<ModalClose onClick={() => {
                    setModalActive(false)
                }}><Icon28Close style={{color: 'var(--tg-theme-text-color)'}} /></ModalClose>}>Confirm payment</ModalHeader>}
                dismissible={false}
                >
                <Placeholder
                    description={`You will send`}
                    header="Are you sure?"
                    action={<Button onClick={() => {
                        handleSubmitPayment()
                    }} stretched loading={loading}>Confirm</Button>}
                >
                </Placeholder>
            </Modal>
        </div>
    )
}

export default PaymnetQR