import { Avatar, Button, List, Placeholder, Text } from "@telegram-apps/telegram-ui"
import { useNavigate } from "react-router-dom"
import Loader from "../../../helpers/loader";
import { useEffect, useState } from "react";
import MainButton from "../../../helpers/mainbutton";

import CardImg from "./../../../assets/img/card/card.png"

const SuccessOpenCard = ({id, brand, name}) => {
    const nav = useNavigate()
    const [imageSrc, setImageSrc] = useState(null);
    useEffect(() => {
        const img = new Image();
        let string = CardImg
        /*if(name?.includes("google")) {
            string = "/card/gp.png"
        } else if(name?.includes("apple")) {
            string = "/card/ap.png"
        } else if (name?.includes("virtual")) {
            string = "/card/vr.png"
        }*/
        img.src = string
        img.onload = () => {
            console.log("Image preloaded");
            setImageSrc(img.src);
        };
    }, []);

    const handleClick = async () => {
        nav("/")
    };
    /*useEffect(() => {
        const mainButton = window.Telegram.WebApp.MainButton;

        
        
        mainButton.offClick(handleClick);
        mainButton.disable()
        mainButton.show();
        mainButton.enable();
        mainButton.setText("Open card");
        mainButton.onClick(handleClick);

        return () => {
            mainButton.offClick(handleClick);
            mainButton.hide();
        };
    }, []);*/

    if(imageSrc === null) return <Loader />
    return (
        <>
        <List className="full_w"> 
            <div></div>
            <Placeholder header={<Text style={{fontSize:"32px"}}>Success!</Text>}
            description={<Text className="ordinary__text" style={{fontSize:"17px"}}>Your card is ready to use</Text>}>
                {/*<Avatar size={64} src="/img/success.png" />*/}
                <div className="card__block__create">
                    <img src={imageSrc} className="card_bg_full"/>
                       
                        <div style={{display:'flex', alignItems:"center", gap:"5px"}} className="card__block__img">
                            <img src="/img/card_l.png" style={{width:"33px", height:"33px"}}/>
                            <Text className="card__block__img_text" style={{fontSize:"14px"}}>BFinance</Text>
                        </div>
                        <img src="/img/paypass.png" className="card__block__img_pay_create"/>
                            
                    <div className="card__block__id" style={{bottom:"10px"}}>
                                
                                
                                    <Text className="card_number" style={{userSelect:"all", fontSize:"17px"}} >
                                    **** **** **** ****
                                </Text>
                                <div className="card__block__id__FLEX" style={{marginTop:"3px"}}>
                                    <div className="card__block__id__block">
                                        <Text className="card__block__id__block_t"style={{fontSize:"8px", lineHeight:"8px"}}>Expire</Text>
                                        <Text className="card__block__id__block_v" style={{userSelect:"all", fontSize:"12px"}}>****</Text>
                                    </div>
                                    <div className="card__block__id__block">
                                        <Text className="card__block__id__block_t"style={{fontSize:"8px", lineHeight:"8px"}}>CVV</Text>
                                        <Text className="card__block__id__block_v" style={{userSelect:"all", fontSize:"12px"}}>***</Text>
                                    </div>
                                </div>
                                

                            </div>
                            {brand === "MasterCard" ? <img src="/img/Mastercard.png" className="card__block__logo__create"/> : <img src="/img/visa.png" className="card__block__logo__create"/>}
                            
                    
                </div>
            </Placeholder>
            <div></div>
            {/*<Button size="l" stretched onClick={() => nav("/cards/"+id)}>Open card</Button>*/}
        </List>
        <MainButton text={"Open card"} myButton withoutTabbar func={handleClick}/>
        </>
    )
}

export default SuccessOpenCard