import { createSlice } from "@reduxjs/toolkit";
import { getRatesThunk } from "../thunks/settingsThunk";

const initialState = {
  rates: null,
  error: ""
};

const settingsReducer = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setRates(state, action) {
      state.rates = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRatesThunk.fulfilled, (state, action) => {
        state.rates = action.payload;
      })
      .addCase(getRatesThunk.rejected, (state, action) => {
        state.error = action.payload; 
      });
  },
});

export const { setRates } = settingsReducer.actions;

export default settingsReducer.reducer;
