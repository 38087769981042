import Back from "../../../helpers/back"


const PrivacyPolicyData = ({setPolisyStatus}) => {
    return (
        <>
            <Back callback={() => setPolisyStatus(false)} />
            <iframe className="iframe" src="https://cdn.bfinance.app/privacy-policy.pdf#toolbar=0"/>
        </>
    )
}

export default PrivacyPolicyData