import { combineReducers, configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/userReducer';
import settingsReducer from './reducers/settingsReducer';
import logger from 'redux-logger';
import cardsReducer from './reducers/cardsReducer';

const rootReducer = combineReducers({
  user: userReducer,
  settings: settingsReducer,
  cards: cardsReducer
})


const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    //middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  });
}



export default setupStore;
