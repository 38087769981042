import { Avatar, Badge, Banner, Button, Cell, Image, InlineButtons, List, Modal, Placeholder, Section, Spinner, Text, Title } from "@telegram-apps/telegram-ui";
import React, { useDebugValue, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TapBar from "../components/tabbar/tabbar";
import { useNotification } from "../../helpers/notification";
import { ModalHeader } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import { ModalClose } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalClose/ModalClose";
import { Icon28Close } from "@telegram-apps/telegram-ui/dist/icons/28/close";
import { BalanceBlock } from "../../modules/BalanceBlock";
import { useDispatch, useSelector } from "react-redux";
import { getCardsThunk } from "../../store/thunks/cardsThunks";
import Loader from "../../helpers/loader";
import OverviewBlocks from "../../modules/OverviewBlocks/OverviewBlocks";

const MainPage = ({ me, fetchDataKyc }) => {
    const nav = useNavigate();
    const dispatch = useDispatch()
    const { cards, loading } = useSelector(state => state.cards)
    const {user: userInfo} = useSelector(state => state.user)
    

    useEffect(() => {
        dispatch(getCardsThunk())
    }, [])

    const location = useLocation()

    const getQueryParams = () => {
        return new URLSearchParams(location.search);
    };

    const esim = getQueryParams().get("tgWebAppStartParam")
    if(String(esim).includes("esim_")) {
        return nav(`/online/payments?tgWebAppStartParam=${esim}`)
    } 


    console.log(userInfo.user.newUser)
    if(loading) return <Loader />
    
    return (
        <>
            <TapBar active={"main"} me={me} fetchDataKyc={fetchDataKyc}/>
            <div style={{paddingBottom:"76px"}}>
                <BalanceBlock />
            </div>
        </>
    );
};

export default MainPage;


/*

                            
                            <Cell subtitle={`${Number(me?.user?.balances?.usd).toFixed(2)} USDT`} onClick={() => nav("/balance/usdt")} after={<Text>${`${Number(me?.user?.balances?.usd).toFixed(2)}`}</Text>} before={<Avatar src="/img/usdt.png" />}>
                                Tether USD
                            </Cell>
                            <Cell onClick={() => nav("/balance/trx")} after={<Text>${(Number(me?.user?.balances?.trx) * Number(rates?.trx?.usd)).toFixed(2)}</Text>}  subtitle={`${me?.user?.balances?.trx ? Number(me?.user?.balances?.trx).toFixed(2) : 0} TRON`} before={<Avatar src="/img/trx.png" />}>
                                TRON
                            </Cell>
                            <Cell onClick={() => nav("/balance/bnb")} after={<Text>${(Number(me?.user?.balances?.bnb) * Number(rates?.bnb?.usd)).toFixed(2)}</Text>} subtitle={`${me?.user?.balances?.bnb ? Number(me?.user?.balances?.bnb).toFixed(6) : 0} BNB`} before={<Avatar src="/img/bnb.png" />}>
                                BNB
                            </Cell>
                            <Cell onClick={() => nav("/balance/bbx")} after={<Text>${(Number(me?.user?.balances?.ton) * Number(rates?.bbx?.usd)).toFixed(2)}</Text>} subtitle={`${me?.user?.balances?.bbx ? Number(me?.user?.balances?.bbx).toFixed(2) : 0} BBX`} before={<Avatar src="/img/bbx.png" />}>
                                BITBIX
                            </Cell>
                            */