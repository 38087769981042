import { Avatar, Chip, InlineButtons, Text } from "@telegram-apps/telegram-ui"
import { InlineButtonsItem } from "@telegram-apps/telegram-ui/dist/components/Blocks/InlineButtons/components/InlineButtonsItem/InlineButtonsItem"
import { Icon24Arrow2SquarepathOutline, Icon24PaymentCardOutline, Icon28ArrowUpCircleOutline, Icon28MoneySendOutline, Icon32SendCircle, Icon36CoinsStacks2Outline } from "@vkontakte/icons"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import VibrateModule from "../../../helpers/vibrate"
import { List } from "react-virtualized"
import { useNotification } from "../../../helpers/notification"
import { useDispatch, useSelector } from "react-redux"
import { postKycThunk } from "../../../store/thunks/userThunk"


const InlineButtonsComponent = () => {
    const dispatch = useDispatch()
    const {showNotification} = useNotification()
    const nav = useNavigate()

    const {user: userInfo, kyc: kycInfo} = useSelector(state => state.user)

    console.log(userInfo?.user?.sumsubVerified)
    return (
        <>
            <InlineButtons>
                <VibrateModule>
                    <Chip onClick={() => {
                        nav("/topup")
                    }} mode="mono" className="main__button__nav" before={<Icon28ArrowUpCircleOutline width={16} height={16} style={{rotate:"180deg"}} />}>
                        Top-up
                    </Chip>
                </VibrateModule>
                <VibrateModule>
                    <Chip onClick={() => {
                        //if(/*Number(me?.user?.balances?.usd) <= 0 &&*/ me?.user?.sumsubVerified === false) return showNotification("Error", "Not enough balance", "error"); 
                        if(userInfo?.user?.sumsubVerified === false) return dispatch(postKycThunk())
                        nav("/withdraw")
                    }} mode="outline" className="main__button__nav" before={<Icon28ArrowUpCircleOutline width={16} height={16}  />}>
                        Withdraw
                    </Chip>
                </VibrateModule>
             </InlineButtons>
             {/*me?.user?.haveAccess == true && <VibrateModule>
                <Chip  onClick={() => {
                    nav("/earn")
                }} mode="outline" className="main__button__nav" before={<Icon36CoinsStacks2Outline width={16} height={16}  />}>
                    Earn
                </Chip>
            </VibrateModule>*/}
            {<VibrateModule>
                <Chip onClick={() => {
                    nav("/open/card")
                }} mode="outline" className="main__button__nav" before={<Icon24PaymentCardOutline width={16} height={16}  />}>
                    New card
                </Chip>
            </VibrateModule>}
        </>
    )
}

export default InlineButtonsComponent