import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCards } from "../../api/api";


export const getCardsThunk = createAsyncThunk(
    "cards/getCards",
    async (ref, thunkAPI) => {
      const response = await getCards();
  
      if (response.status === "error") {
        console.error(response)
        return thunkAPI.rejectWithValue(response.error.message);
      }
  
      return response.data;
    }
  );